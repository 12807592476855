import { QuestionId } from "@sp-crm/core";
import {
    FIELD_PICKER_TYPE_SEPARATOR,
    FieldPickerField,
} from "components/reports/show/custom-reports/custom-reports-helpers";
import { SearchInput } from "components/ui/search-input";
import React from "react";
import { EntityConditionFieldPicker } from "./types";

interface EntitySearchableFieldListProps {
    fieldGroups: Map<string, FieldPickerField[]>;
    onSelected: (field: EntityConditionFieldPicker | null) => void;
    searchText: string;
    onSearchTextChange: (searchText: string) => void;
    allowEmpty?: boolean;
}

const noneSentinel = "__none__";

export const EntitySearchableFieldList: React.FC<
    EntitySearchableFieldListProps
> = props => {
    const { fieldGroups, onSelected, searchText, onSearchTextChange, allowEmpty } = props;

    const handleSearchText = React.useCallback(
        async (e: React.ChangeEvent<HTMLInputElement>) => {
            e.preventDefault();
            onSearchTextChange(e.target.value);
        },
        [onSearchTextChange],
    );

    React.useEffect(() => {
        return () => {
            onSearchTextChange("");
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleFieldChange = React.useCallback(
        (newValue: string) => {
            if (newValue === noneSentinel) {
                onSelected(null);
                return;
            }

            const [fieldType, fieldName] = newValue.split(FIELD_PICKER_TYPE_SEPARATOR);
            if (fieldType === "field") {
                onSelected({
                    questionId: null,
                    fieldName: fieldName,
                    relation: null,
                });
            } else if (fieldType === "questionId") {
                onSelected({
                    questionId: fieldName as QuestionId,
                    fieldName: null,
                    relation: null,
                });
            } else if (fieldType === "relation") {
                onSelected({
                    questionId: null,
                    fieldName: null,
                    relation: {
                        name: fieldName,
                    },
                });
            } else {
                console.warn("Something seems to have gone terribly awry!");
            }
        },
        [onSelected],
    );

    return (
        <div>
            <div className="p-2 lg:p-4">
                <SearchInput value={searchText} onChange={handleSearchText} />
            </div>
            <div className="max-h-64 overflow-scroll p-2 lg:p-4 space-y-2">
                <ul className="space-y-1 lg:space-y-2">
                    {allowEmpty ? (
                        <li
                            className="cursor-pointer -mx-4 -my-1 px-4 py-1 hover:bg-brand-100 hover:text-brand-700 rounded"
                            onClick={() => handleFieldChange(noneSentinel)}>
                            -- None --
                        </li>
                    ) : null}
                    {Array.from(fieldGroups.entries()).map(
                        ([categoryLabel, fields], index) => (
                            <li key={index}>
                                <p className="text-gray-700 text-sm font-semibold">
                                    {categoryLabel}
                                </p>
                                <ul className="ml-3 mt-0.5">
                                    {fields
                                        .sort((a, b) => {
                                            if (
                                                typeof a.order === "number" &&
                                                typeof b.order === "number"
                                            ) {
                                                return a.order - b.order;
                                            }

                                            return a.title
                                                .toLocaleLowerCase()
                                                .localeCompare(
                                                    b.title.toLocaleLowerCase(),
                                                );
                                        })
                                        .map(f => (
                                            <li
                                                key={f.key}
                                                className="cursor-pointer -mx-4 -my-1 px-4 py-1 hover:bg-brand-100 hover:text-brand-700 rounded"
                                                onClick={() =>
                                                    handleFieldChange(f.value)
                                                }>
                                                {f.title}
                                            </li>
                                        ))}
                                </ul>
                            </li>
                        ),
                    )}
                </ul>
            </div>
        </div>
    );
};
