import { EmailTemplateType } from "@sp-crm/core";
import {
    ComposeMessageBasicQuery,
    GetTemplateQuery,
    GetTemplatesQuery,
} from "../../generated/graphql";
import { Template, UnmigratedTemplate } from "./template";

export const templateFromRequest = (
    t:
        | GetTemplateQuery["getTemplate"]
        | ComposeMessageBasicQuery["getTemplates"]["templates"][0],
): UnmigratedTemplate => {
    return {
        id: t.template.id,
        body: t.template.body ?? "",
        description: t.template.description ?? "Untitled",
        isDefaultSignature: t.isDefaultSignature,
        migrationVersion: t.template.migrationVersion,
        subject: t.template.subject,
        type: t.template.type as EmailTemplateType,
        attachments: t.template.attachments,
    };
};

export const upgradeTemplate = (template: UnmigratedTemplate): Template => {
    if (template.migrationVersion > 0) {
        return template as Template;
    }
    const newBody = document.createElement("div");
    newBody.innerHTML = template.body;
    for (const child of Array.from(newBody.children)) {
        if (child.tagName === "p" || child.tagName === "P") {
            const newChild = document.createElement("div");
            newChild.innerHTML = child.innerHTML;
            newBody.replaceChild(newChild, child);
        }
    }
    return {
        ...template,
        body: newBody.innerHTML,
        migrationVersion: 1,
    } as Template;
};

export const getTemplatesByType = (
    templates:
        | GetTemplatesQuery["getTemplates"]["templates"]
        | ComposeMessageBasicQuery["getTemplates"]["templates"],
    type: EmailTemplateType[],
) => {
    return templates
        .filter(t => type.includes(t.template.type as EmailTemplateType))
        .map(templateFromRequest)
        .map(upgradeTemplate);
};

export const getInsertableTemplates = (
    templates:
        | GetTemplatesQuery["getTemplates"]["templates"]
        | ComposeMessageBasicQuery["getTemplates"]["templates"],
) => {
    return getTemplatesByType(templates, ["insertable"]);
};

export const getSignatureTemplates = (
    templates:
        | GetTemplatesQuery["getTemplates"]["templates"]
        | ComposeMessageBasicQuery["getTemplates"]["templates"],
) => {
    return getTemplatesByType(templates, ["signature"]);
};
