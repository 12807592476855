import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import React from "react";
import { sanitizeUri } from "util/uri";

interface UrlButtonProps {
    url: string | undefined | null;
}

/**
 * Meant to be used with vanilla input to recreate legacy `UrlInput`
 * @param props `UrlButtonProps`
 * @returns Conditionally rendered widget enabling an external link or outgoing phone call
 */
export const UrlButton: React.FC<UrlButtonProps> = props => {
    const { url } = props;
    if (!url || url.trim() === "") return null;

    return (
        <div className="pb-4">
            <a href={sanitizeUri(url)} target="_blank" rel="noopener noreferrer">
                <ArrowTopRightOnSquareIcon className="w-5 h-5" />
            </a>
        </div>
    );
};
