import { CalendarDate } from "@sp-crm/core";
import * as React from "react";

interface DateProps {
    className?: string;
    label: string;
    initial?: Date;
    domId?: string;
    value?: Date;
    onCommit?: (newValue: Date | null) => void;
    onChange?: (newValue: Date | null) => void;
    showTime?: boolean;
}

interface DateState {
    modifiedValue: Date | null;
}

/**
 * DateInput
 * @deprecated use sp-v3-components CalendarDateInput instead
 */
export class DateInput extends React.Component<DateProps, DateState> {
    constructor(props: DateProps) {
        super(props);
        this.state = {
            modifiedValue: props.value || props.initial,
        };
    }

    changeDate(newDate: string): void {
        const theDate: CalendarDate | null =
            newDate === "" ? null : CalendarDate.parse(newDate).getOrElse(null);

        while (theDate?.year >= 10000) {
            theDate.year = Math.floor(theDate.year / 10);
        }

        const newState = Object.assign({}, this.state, {
            modifiedValue: theDate?.toString(),
        });
        this.setState(newState);
        if (this.props.onChange) {
            this.props.onChange(newState.modifiedValue);
        }
        if (this.props.onCommit) {
            this.props.onCommit(newState.modifiedValue);
        }
    }

    getDateValue(): string | null {
        const theDate: Date | null = this.props.value ?? this.state.modifiedValue;
        if (!theDate) {
            return "";
        }
        if (typeof theDate === "string") {
            const calendarDate = CalendarDate.parse(theDate);
            return calendarDate.map(x => x.toString()).getOrElse("");
        }
        return theDate.toISOString().split("T")[0];
    }

    render() {
        return (
            <div className="w-full">
                <label>
                    <div className="mb-1">{this.props.label}</div>
                    <input
                        className="form-input rounded-sm md:rounded w-full disabled:bg-gray-100 border-gray-300 hover:border-brand-600 active:border-brand-400 focus:ring-2 focus:ring-brand-300"
                        type="date"
                        value={this.getDateValue()}
                        onChange={e => this.changeDate(e.target.value)}
                        max="2999-12-31"
                    />
                </label>
            </div>
        );
    }
}
