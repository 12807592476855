import React, { useCallback, useEffect, useState } from "react";
import { TimePart } from "./types";

interface DateInputTimePartProps {
    value: TimePart;
    onChange: (value: TimePart) => void;
}

export const DateInputTimePartInput: React.FC<DateInputTimePartProps> = props => {
    const { value, onChange } = props;
    const [currentValue, setCurrentValue] = useState<
        TimePart | string | undefined | null
    >(value);
    useEffect(() => {
        setCurrentValue(value);
    }, [value]);
    const callback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const userInput = e.target.value;
            setCurrentValue(userInput);
            if (userInput && userInput !== "") {
                onChange(userInput as TimePart);
            }
        },
        [onChange],
    );
    return (
        <input
            className="form-input rounded-sm md:rounded w-full disabled:bg-gray-100 border-gray-300 hover:border-brand-600 active:border-brand-400 focus:ring-2 focus:ring-brand-300"
            type="time"
            value={currentValue ?? ""}
            onChange={callback}
            data-testid="timeinput"
        />
    );
};
