import { NoteEntityId, NoteId, genEntityId } from "@sp-crm/core";
import { useDebouncedState } from "components/shared/hooks";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import { TextArea } from "components/ui/textarea";
import {
    GetNotesForEntityQuery,
    UpdateNoteMutationVariables,
    useUpdateNoteMutation,
} from "generated/graphql";
import { produce } from "immer";
import * as React from "react";

type DraftNote = Partial<GetNotesForEntityQuery["getNotesForEntity"][0]>;

interface NotesInputProps {
    entityId: NoteEntityId;
    initial: DraftNote[];
    refetch: () => void;
    onNoteChanged: (note: GetNotesForEntityQuery["getNotesForEntity"][0]) => void;
}

export const NotesInput: React.FC<NotesInputProps> = props => {
    const { entityId, initial, refetch, onNoteChanged } = props;

    const initialDrafts = React.useMemo(() => initial.filter(i => i.isDraft), [initial]);

    const [notes, setNotes] = React.useState<DraftNote[]>(
        initialDrafts?.length > 0
            ? initialDrafts
            : [
                  {
                      _id: genEntityId<NoteId>(),
                      text: "",
                      isDraft: true,
                  },
              ],
    );

    React.useEffect(() => {
        setNotes(prev => {
            const newDrafts: DraftNote[] = [];

            prev.forEach(d => {
                const existingInitial = initial.find(i => i._id === d._id);
                if (!existingInitial || existingInitial.isDraft) {
                    newDrafts.push(d);
                }
            });
            initial.forEach(note => {
                if (note.isDraft) {
                    const existingDraftNote = prev.find(d => d._id === note._id);
                    if (!existingDraftNote) {
                        newDrafts.push(note);
                    }
                }
            });

            if (newDrafts.length === 0) {
                newDrafts.push({
                    _id: genEntityId<NoteId>(),
                    text: "",
                    isDraft: true,
                });
            }

            return newDrafts;
        });
    }, [initial]);

    React.useEffect(() => {
        return () => {
            refetch();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleDraftSaved = React.useCallback(
        (noteId: NoteId, text: string) => {
            setNotes(prev => {
                return produce(prev, draft => {
                    const draftNote = draft.find(d => d._id === noteId);
                    if (draftNote) {
                        draftNote.text = text;
                    }
                });
            });
        },
        [setNotes],
    );

    return (
        <div>
            {notes.map(draftNote => (
                <NoteInput
                    key={draftNote._id}
                    entityId={entityId}
                    draftNote={draftNote}
                    onSavingDraft={handleDraftSaved}
                    onNoteSaved={onNoteChanged}
                />
            ))}
        </div>
    );
};

interface NoteInputProps {
    entityId: NoteEntityId;
    draftNote: DraftNote;
    onSavingDraft: (noteId: NoteId, text: string) => void;
    onNoteSaved: (note: GetNotesForEntityQuery["getNotesForEntity"][0]) => void;
}

const NoteInput: React.FC<NoteInputProps> = props => {
    const { entityId, draftNote, onSavingDraft, onNoteSaved } = props;
    const updateNote = useUpdateNoteMutation();
    const [hasPublishedNote, setHasPublishedNote] = React.useState(false);
    const [noteText, setNoteText, debouncedNoteText] = useDebouncedState(
        draftNote.text,
        3000,
    );
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const isSubmitDisabled =
        noteText === "" || noteText === null || noteText === undefined || isSubmitting;
    const onChange = React.useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setNoteText(e.target.value);
        },
        [setNoteText],
    );
    const saveDraft = React.useCallback(async () => {
        if (hasPublishedNote || draftNote.text === noteText) {
            return;
        }
        const payload: UpdateNoteMutationVariables = {
            entityId,
            noteId: draftNote._id,
            params: {
                text: noteText,
                isPublished: false,
            },
        };
        onSavingDraft(draftNote._id, noteText);
        const result = await updateNote.mutateAsync(payload);
        onNoteSaved(result.updateNote);
    }, [
        updateNote,
        entityId,
        draftNote._id,
        draftNote.text,
        hasPublishedNote,
        onSavingDraft,
        onNoteSaved,
        noteText,
    ]);
    React.useEffect(() => {
        saveDraft();
    }, [debouncedNoteText]); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        return () => {
            saveDraft();
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const submit = React.useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            if (e) {
                e.preventDefault();
            }
            setHasPublishedNote(true);
            const payload: UpdateNoteMutationVariables = {
                entityId,
                noteId: draftNote._id,
                params: {
                    text: noteText,
                    isPublished: true,
                },
            };
            setIsSubmitting(true);
            const result = await updateNote.mutateAsync(payload);
            onNoteSaved(result.updateNote);
            setIsSubmitting(false);
        },
        [updateNote, entityId, draftNote._id, noteText, setIsSubmitting, onNoteSaved],
    );

    return (
        <form onSubmit={submit} className="space-y-1">
            <TextArea
                onBlur={saveDraft}
                label="Add Note"
                rows={3}
                value={noteText}
                onChange={onChange}
                autoGrow={true}
            />
            <div className="flex justify-end">
                {isSubmitDisabled ? (
                    <SecondaryButton disabled type="submit">
                        {isSubmitting ? <Spinner /> : <span>Add Note</span>}
                    </SecondaryButton>
                ) : (
                    <PrimaryButton type="submit">Add Note</PrimaryButton>
                )}
            </div>
        </form>
    );
};
