import * as Common from "@sp-crm/core";
import { Contact, validEmail } from "@sp-crm/core";
import * as React from "react";
import { UserProvidedMessages } from "react-widgets/esm/messages";
import Multiselect from "react-widgets/Multiselect";

interface RecipientsFieldProps {
    onChange: (e: Common.IContact[]) => void;
    recipients: Common.IContact[];
    suggestions?: Common.IContact[];
    suggestionHeader?: string;
    disabled?: boolean;
    label: string;
}

interface RecipientSelection {
    text: string;
    value: string;
}

const messages: UserProvidedMessages = {
    createOption: (_value: unknown, searchTerm: string) => [
        " Add email",
        searchTerm && " ",
        searchTerm && <strong key="_">{`"${searchTerm}"`}</strong>,
    ],
};

export const RecipientsField: React.FC<RecipientsFieldProps> = props => {
    const { recipients, onChange, suggestions, label } = props;
    const value: RecipientSelection[] = (recipients || [])
        .filter(x => !!x.email1)
        .map(s => ({
            text: s.name && s.name !== s.email1 ? `${s.name} <${s.email1}>` : s.email1,
            value: s.email1,
        }))
        .reduce((acc, cur) => {
            if (
                !acc.find(
                    x =>
                        (x.value ?? "").toLowerCase() === (cur.value ?? "").toLowerCase(),
                )
            ) {
                acc.push(cur);
            }
            return acc;
        }, []);

    const data: RecipientSelection[] = React.useMemo(() => {
        const initial = [
            ...value,
            ...(suggestions || [])
                .filter(x => !!x.email1)
                .map(s => ({
                    text: s.name ? `${s.name} <${s.email1}>` : s.email1,
                    value: s.email1,
                })),
        ];

        const withoutDuplicates = initial.reduce((acc, cur) => {
            if (
                !acc.find(
                    x =>
                        (x.value ?? "").toLowerCase() === (cur.value ?? "").toLowerCase(),
                )
            ) {
                acc.push(cur);
            }
            return acc;
        }, []);

        return withoutDuplicates;
    }, [value, suggestions]);

    const internalOnChange = React.useCallback(
        (values: RecipientSelection[]) => {
            const payload: Common.IContact[] = [];
            if (values && values.length > 0) {
                const allContacts = [...suggestions, ...recipients];
                values.forEach(v => {
                    const contact = allContacts.find(c => c.email1 === v.value);
                    if (contact) {
                        payload.push(contact);
                    } else if (validEmail(v.value)) {
                        payload.push(Contact.load({ email1: v.value }));
                    }
                });
            }
            onChange(payload);
        },
        [onChange, suggestions, recipients],
    );
    const onCreate = React.useCallback(
        (email: string) => {
            if (validEmail(email)) {
                const newContact = Contact.load({ email1: email });
                const payload = [...(recipients || []), newContact];
                onChange(payload);
            }
        },
        [onChange, recipients],
    );

    return (
        <div>
            {label ? <label>{label}</label> : null}
            <Multiselect
                messages={messages}
                allowCreate
                data={data}
                value={value}
                dataKey="value"
                textField="text"
                onChange={internalOnChange}
                onCreate={onCreate}
            />
        </div>
    );
};
