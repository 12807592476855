import { powerOfAttorneyDescription } from "@sp-crm/core";
import React from "react";
import Multiselect from "react-widgets/Multiselect";

const powerOfAttorneyOptions = [
    { value: "empty", text: powerOfAttorneyDescription.empty }, // explicitly, no POA
    { value: "all", text: powerOfAttorneyDescription.all },
    { value: "financial", text: powerOfAttorneyDescription.financial },
    { value: "medic", text: powerOfAttorneyDescription.medic },
    { value: "financial_real", text: powerOfAttorneyDescription.financial_real },
    { value: "guardian", text: powerOfAttorneyDescription.guardian },
];

interface PowerOfAttorneySelectProps {
    value: string[];
    onChange: (newVal: string[]) => void;
}

export const PowerOfAttorneySelect: React.FC<PowerOfAttorneySelectProps> = props => {
    const { value, onChange } = props;

    const handleChange = React.useCallback(
        (newVal: typeof powerOfAttorneyOptions[number][]) => {
            onChange(newVal.map(x => x.value));
        },
        [onChange],
    );

    return (
        <div>
            <label>
                Power of attorney
                <br />
                <Multiselect
                    data={powerOfAttorneyOptions}
                    value={value}
                    dataKey="value"
                    textField="text"
                    onChange={handleChange}
                />
            </label>
        </div>
    );
};
