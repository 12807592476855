import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    Popover,
    PopoverButton,
    PopoverPanel,
} from "@headlessui/react";
import { LinkIcon } from "@heroicons/react/24/outline";
import { EmailTemplateId } from "@sp-crm/core";
import { multiFieldPrompt } from "components/ui/fancy-multi-field-dialog";
import { SearchInput } from "components/ui/search-input";
import { secondaryClasses } from "components/ui/secondary-button";
import { AdvancedSearchEntityType } from "generated/graphql";
import React from "react";
import {
    adjustLinkSelection,
    clearFormat,
    insertLink,
    setAlignment,
    setBackgroundColor,
    setFontSize,
    setIndentation,
    setTextColor,
    toggleBold,
    toggleBullet,
    toggleItalic,
    toggleNumbering,
    toggleUnderline,
} from "roosterjs";
import { EditorAdapter } from "roosterjs-editor-adapter";
import { ContentPosition } from "roosterjs-editor-types";
import { ColorPickerPanel } from "./color-picker-panel";
import { PlaceholderPopover } from "./placeholder-popover";
import {
    AlignCenterIcon,
    AlignLeftIcon,
    AlignRightIcon,
    BoldIcon,
    ClearFormattingIcon,
    HighlightIcon,
    IndentDecreaseIcon,
    IndentIncreaseIcon,
    InsertPlaceholderIcon,
    ItalicIcon,
    ListIcon,
    ListNumbersIcon,
    SignatureIcon,
    TextColorIcon,
    TextSizeIcon,
    TriangleDownIcon,
    UnderlineIcon,
} from "./ribbon-icons";
import { HtmlToken, signatureClass, Template } from "./template";
import { TemplateMenu } from "./template-menu";
import { TextSizePickerPanel } from "./text-size-picker-panel";

interface Props {
    editor: EditorAdapter;
    templates?: Template[];
    signatures?: Template[];
    onTemplateInserted?: (template: Template) => void;
    tokenReplacements?: Record<string, string>;
    onTokenInserted?: (tokenValue: string) => boolean;
    placeholderTypes?: AdvancedSearchEntityType[];
    tokens?: HtmlToken[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- eslintintroduction
function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(" ");
}

export const Ribbon: React.FC<Props> = props => {
    const {
        editor,
        templates,
        signatures,
        onTemplateInserted,
        tokenReplacements,
        onTokenInserted,
        placeholderTypes,
        tokens,
    } = props;

    const handleSignature = React.useCallback(
        (templateId: EmailTemplateId) => {
            if (templateId && editor) {
                const signature =
                    (signatures || []).find(t => t.id === templateId) || null;
                if (signature) {
                    const doc = editor.getDocument();
                    const existingSignatures = Array.from(
                        doc.getElementsByClassName(signatureClass),
                    );
                    for (const n of existingSignatures) {
                        editor.deleteNode(n);
                    }
                    editor.insertContent(
                        `<div class="${signatureClass}">${signature.body}</div>`,
                        { position: ContentPosition.End },
                    );
                }
            }
        },
        [editor, signatures],
    );

    const handleToken = (token: HtmlToken) => {
        if (token && editor) {
            editor.insertContent(`{{${token.value}}}`);

            if (onTokenInserted) {
                onTokenInserted(token.value);
            }
        }
    };

    const boldHandler = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            toggleBold(editor);
        },
        [editor],
    );

    const italicHandler = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            toggleItalic(editor);
        },
        [editor],
    );

    const underlineHandler = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            toggleUnderline(editor);
        },
        [editor],
    );

    const alignLeftHandler = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setAlignment(editor, "left");
        },
        [editor],
    );

    const alignCenterHandler = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setAlignment(editor, "center");
        },
        [editor],
    );

    const alignRightHandler = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setAlignment(editor, "right");
        },
        [editor],
    );

    const bulletHandler = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            toggleBullet(editor);
        },
        [editor],
    );

    const numberHandler = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            toggleNumbering(editor);
        },
        [editor],
    );
    const increaseIndentHandler = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setIndentation(editor, "indent");
        },
        [editor],
    );
    const decreaseIndentHandler = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setIndentation(editor, "outdent");
        },
        [editor],
    );
    const textSizeHandler = React.useCallback(
        (fontSize: string) => {
            setFontSize(editor, fontSize);
        },
        [editor],
    );
    const backgroundColorHandler = React.useCallback(
        (color: string) => {
            setBackgroundColor(editor, color);
        },
        [editor],
    );
    const foregroundColorHandler = React.useCallback(
        (color: string) => {
            setTextColor(editor, color);
        },
        [editor],
    );

    const clearFormatHandler = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            clearFormat(editor);
        },
        [editor],
    );

    const insertLinkHandler = React.useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            const [text, href] = adjustLinkSelection(editor);
            const { values, button } = await multiFieldPrompt({
                title: href && href.trim() !== "" ? "Edit Link" : "Insert link",
                fields: [
                    { name: "text", label: "Link text" },
                    { name: "href", label: "Link location" },
                ],
                currentValues: { href, text },
                buttons: [
                    { text: "OK", primary: true, callbackName: "ok" },
                    { text: "Cancel", primary: false, callbackName: "cancel" },
                ],
            });
            if (button === "ok" && values.href && values.text) {
                insertLink(editor, values.href, values.href, values.text);
            }
        },
        [editor],
    );

    const [signatureFilter, setSignatureFilter] = React.useState("");

    const handleSignatureSearch = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setSignatureFilter(e.target.value);
        },
        [],
    );

    const filteredSignatures = React.useMemo(() => {
        return signatures?.filter(t =>
            t.description.toLowerCase().includes(signatureFilter.toLowerCase()),
        );
    }, [signatures, signatureFilter]);

    return (
        <div className="flex items-center space-x-4 bg-gray-200 p-2 rounded-t">
            <div className="flex items-center space-x-2">
                <button
                    type="button"
                    className="rounded-sm hover:bg-gray-400"
                    onClick={boldHandler}>
                    <BoldIcon className="w-5 h-5" />
                </button>
                <button
                    type="button"
                    className="rounded-sm hover:bg-gray-400"
                    onClick={italicHandler}>
                    <ItalicIcon className="w-5 h-5" />
                </button>
                <button
                    type="button"
                    className="rounded-sm hover:bg-gray-400"
                    onClick={underlineHandler}>
                    <UnderlineIcon className="w-5 h-5" />
                </button>
                <button
                    type="button"
                    className="rounded-sm hover:bg-gray-400"
                    onClick={insertLinkHandler}>
                    <LinkIcon className="w-5 h-5" />
                </button>
                <Popover as="div" className="relative">
                    <div className="flex items-center">
                        <PopoverButton className="rounded-sm hover:bg-gray-400">
                            <div className="flex items-center">
                                <TextSizeIcon className="w-5 h-5" />
                                <TriangleDownIcon className="w-1.5 h-1.5" />
                            </div>
                        </PopoverButton>
                    </div>
                    <PopoverPanel className="origin-top-right absolute left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                        <TextSizePickerPanel
                            editor={editor}
                            current={e =>
                                e.getStyleBasedFormatState().fontSize ||
                                e.getDefaultFormat().fontSize
                            }
                            onTextSizeSelected={textSizeHandler}
                        />
                    </PopoverPanel>
                </Popover>
                <Popover as="div" className="relative">
                    <div className="flex items-center">
                        <PopoverButton className="rounded-sm hover:bg-gray-400">
                            <div className="flex items-center">
                                <HighlightIcon className="w-5 h-5" />
                                <TriangleDownIcon className="w-1.5 h-1.5" />
                            </div>
                        </PopoverButton>
                    </div>
                    <PopoverPanel className="origin-top-right absolute left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                        <ColorPickerPanel
                            editor={editor}
                            current={e =>
                                e.getStyleBasedFormatState().backgroundColor ||
                                e.getDefaultFormat().backgroundColor
                            }
                            label="Background color"
                            onColorSelected={backgroundColorHandler}
                        />
                    </PopoverPanel>
                </Popover>
                <Popover as="div" className="relative">
                    <div className="flex items-center">
                        <PopoverButton className="rounded-sm hover:bg-gray-400">
                            <div className="flex items-center">
                                <TextColorIcon className="w-5 h-5" />
                                <TriangleDownIcon className="w-1.5 h-1.5" />
                            </div>
                        </PopoverButton>
                    </div>
                    <PopoverPanel className="origin-top-right absolute left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                        <ColorPickerPanel
                            editor={editor}
                            current={e =>
                                e.getStyleBasedFormatState().textColor ||
                                e.getDefaultFormat().textColor
                            }
                            label="Text color"
                            onColorSelected={foregroundColorHandler}
                        />
                    </PopoverPanel>
                </Popover>
                <button
                    type="button"
                    className="rounded-sm hover:bg-gray-400"
                    onClick={clearFormatHandler}>
                    <ClearFormattingIcon className="w-5 h-5" />
                </button>
                <button
                    type="button"
                    className="rounded-sm hover:bg-gray-400"
                    onClick={alignLeftHandler}>
                    <AlignLeftIcon className="w-5 h-5" />
                </button>
                <button
                    type="button"
                    className="rounded-sm hover:bg-gray-400"
                    onClick={alignCenterHandler}>
                    <AlignCenterIcon className="w-5 h-5" />
                </button>
                <button
                    type="button"
                    className="rounded-sm hover:bg-gray-400"
                    onClick={alignRightHandler}>
                    <AlignRightIcon className="w-5 h-5" />
                </button>
                <button
                    type="button"
                    className="rounded-sm hover:bg-gray-400"
                    onClick={bulletHandler}>
                    <ListIcon className="w-5 h-5" />
                </button>
                <button
                    type="button"
                    className="rounded-sm hover:bg-gray-400"
                    onClick={numberHandler}>
                    <ListNumbersIcon className="w-5 h-5" />
                </button>
                <button
                    type="button"
                    className="rounded-sm hover:bg-gray-400"
                    onClick={increaseIndentHandler}>
                    <IndentIncreaseIcon className="w-5 h-5" />
                </button>
                <button
                    type="button"
                    className="rounded-sm hover:bg-gray-400"
                    onClick={decreaseIndentHandler}>
                    <IndentDecreaseIcon className="w-5 h-5" />
                </button>
            </div>
            <TemplateMenu
                templates={templates}
                editor={editor}
                onTemplateInserted={onTemplateInserted}
                tokenReplacements={tokenReplacements}
                onTokenInserted={onTokenInserted}
            />
            {signatures && signatures.length > 0 ? (
                <Menu as="div" className="relative inline-block text-left ml-4">
                    <MenuButton className={secondaryClasses}>
                        <div className="flex items-center space-x-2">
                            <SignatureIcon className="w-5 h-5" />
                            <TriangleDownIcon className="w-1.5 h-1.5" />
                        </div>
                    </MenuButton>
                    <MenuItems className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                        <p className="p-2 text-brand-700">Insert signature</p>
                        {signatures.length > 5 ? (
                            <SearchInput
                                value={signatureFilter}
                                placeholder="Filter signatures"
                                onChange={handleSignatureSearch}
                            />
                        ) : null}
                        {filteredSignatures.map(t => (
                            <MenuItem key={t.id}>
                                {({ focus }) => (
                                    <div
                                        className={classNames(
                                            focus
                                                ? "bg-brand-100 text-brand-900"
                                                : "text-gray-700",
                                            "group flex items-center px-4 py-2 text-sm rounded-md",
                                        )}
                                        onClick={() => handleSignature(t.id)}>
                                        {t.description}
                                    </div>
                                )}
                            </MenuItem>
                        ))}
                    </MenuItems>
                </Menu>
            ) : null}
            <PlaceholderPopover
                editor={editor}
                tokenReplacements={tokenReplacements}
                onTokenInserted={onTokenInserted}
                placeholderTypes={placeholderTypes}
            />
            {tokens?.length > 0 ? (
                <Menu as="div" className="relative inline-block text-left ml-4">
                    <MenuButton className={secondaryClasses}>
                        <div className="flex items-center space-x-2">
                            <InsertPlaceholderIcon className="w-5 h-5" />
                            <TriangleDownIcon className="w-1.5 h-1.5" />
                        </div>
                    </MenuButton>
                    <MenuItems className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                        <p className="p-2 text-brand-700">Insert token</p>
                        {tokens.map(t => (
                            <MenuItem key={t.value}>
                                {({ focus }) => (
                                    <div
                                        className={classNames(
                                            focus
                                                ? "bg-brand-100 text-brand-900"
                                                : "text-gray-700",
                                            "group flex items-center px-4 py-2 text-sm rounded-md",
                                        )}
                                        onClick={() => handleToken(t)}>
                                        {t.label}
                                    </div>
                                )}
                            </MenuItem>
                        ))}
                    </MenuItems>
                </Menu>
            ) : null}
        </div>
    );
};
