import { SelectProOption, isSelectProHeaderOption } from "helpers/select-defs";
import React, { Reducer } from "react";
import { getStatesArray } from "util/states";
import { AutosavingInput } from "./autosaving-input";
import { SelectPro } from "./select-pro";
import { Spinner } from "./spinner";

interface AutosavingStateInputProps {
    label: string | JSX.Element;
    initial: string;
    onCommit: (newValue: string) => void;
    disabled?: boolean;
}

const stateOptions = getStatesArray();
const stateKeys: string[] = [];
const recordStateKeys = (option: SelectProOption) => {
    if (isSelectProHeaderOption(option)) {
        option.groupOptions.forEach(recordStateKeys);
    } else {
        stateKeys.push(option.value);
    }
};
stateOptions.forEach(recordStateKeys);

export const AutosavingStateInput: React.FC<AutosavingStateInputProps> = props => {
    const { initial } = props;
    const isWhitespace =
        initial === null || initial === undefined || initial.trim() === "";
    const isKnownState = stateKeys.includes(initial);
    if (isKnownState || isWhitespace) {
        return <AutosavingStateInputSelect {...props} />;
    } else {
        return <AutosavingInput {...props} />;
    }
};

interface ComponentState {
    upstreamValue: string;
    mode: "pristine" | "pending" | "dirty";
    currentValue: string;
    isInKeyboardDelay: boolean;
    typedLetters: string[];
}

interface Props {
    label?: string | JSX.Element;
    initial: string;
    onCommit: (newValue: string) => void;
}

type Action =
    | { type: "keyboardInteraction"; key?: string }
    | { type: "keyboardInteractionTimeout" }
    | { type: "commitPostKeyboardDelay" }
    | { type: "change"; value: string }
    | { type: "blur"; value: string }
    | { type: "updateUpstream"; value: string };

const invalidAction = (action: Action): never => {
    throw new Error(`unknown action type ${action.type}`);
};
const invalidMode = (mode: ComponentState["mode"]): never => {
    throw new Error(`unknown mode ${mode}`);
};

const reducer: Reducer<ComponentState, Action> = (p, a): ComponentState => {
    switch (a.type) {
        case "keyboardInteraction": {
            const typedLetters = [...p.typedLetters, a.key];
            const typedString = typedLetters.join("").toLocaleLowerCase();
            const typedState = stateKeys.find(
                state => state.toLocaleLowerCase() === typedString,
            );
            return {
                ...p,
                currentValue: typedState || p.currentValue,
                isInKeyboardDelay: true,
                typedLetters,
            };
        }
        case "change":
            return {
                ...p,
                mode:
                    p.upstreamValue === a.value
                        ? "pristine"
                        : p.isInKeyboardDelay
                        ? "dirty"
                        : "pending",
                currentValue: a.value,
            };
        case "blur":
            return {
                ...p,
                mode: p.upstreamValue === a.value ? "pristine" : "pending",
                currentValue: a.value,
                isInKeyboardDelay: false,
                typedLetters: [],
            };
        case "keyboardInteractionTimeout":
            return {
                ...p,
                isInKeyboardDelay: false,
                typedLetters: [],
            };
        case "commitPostKeyboardDelay":
            return {
                ...p,
                mode: p.upstreamValue === p.currentValue ? "pristine" : "pending",
            };
        case "updateUpstream":
            switch (p.mode) {
                case "dirty":
                    return { ...p, upstreamValue: a.value };
                case "pending":
                    return {
                        ...p,
                        mode: p.currentValue === a.value ? "pristine" : "pending",
                        upstreamValue: a.value,
                    };
                case "pristine":
                    return { ...p, currentValue: a.value, upstreamValue: a.value };
            }
            return invalidMode(p.mode);
    }
    return invalidAction(a);
};

const AutosavingStateInputSelect: React.FC<
    Props & React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>
> = props => {
    const { initial, onCommit, label, disabled } = props;
    const keyboardTimeoutRef = React.useRef<number | null>(null);
    const clearKeyboardTimeout = React.useCallback(() => {
        if (keyboardTimeoutRef.current) {
            clearTimeout(keyboardTimeoutRef.current);
            keyboardTimeoutRef.current = null;
        }
    }, [keyboardTimeoutRef]);
    const [state, dispatch] = React.useReducer(reducer, {
        upstreamValue: initial,
        currentValue: initial,
        mode: "pristine",
        isInKeyboardDelay: false,
        typedLetters: [],
    });
    const onChange = React.useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            const value = e.target.value;
            dispatch({ type: "change", value });
            if (!state.isInKeyboardDelay) {
                onCommit(value);
            }
        },
        [dispatch, state, onCommit],
    );
    const startKeyboardTimeout = React.useCallback(() => {
        clearKeyboardTimeout();
        keyboardTimeoutRef.current = window.setTimeout(() => {
            dispatch({ type: "keyboardInteractionTimeout" });
        }, 3000);
    }, [clearKeyboardTimeout]);
    const onKeyDown = React.useCallback(
        (e: React.KeyboardEvent<HTMLSelectElement>) => {
            startKeyboardTimeout();
            dispatch({ type: "keyboardInteraction", key: e.key });
        },
        [startKeyboardTimeout],
    );
    const onBlur = React.useCallback(() => {
        clearKeyboardTimeout();
        if (state.mode === "dirty") {
            dispatch({ type: "blur", value: state.currentValue });
            onCommit(state.currentValue);
        }
    }, [clearKeyboardTimeout, state.mode, state.currentValue, onCommit]);
    React.useEffect(() => {
        if (!state.isInKeyboardDelay && state.mode === "dirty") {
            dispatch({ type: "commitPostKeyboardDelay" });
            onCommit(state.currentValue);
        }
    }, [onCommit, state.isInKeyboardDelay, state.mode, state.currentValue]);
    React.useEffect(() => {
        dispatch({ type: "updateUpstream", value: initial });
    }, [dispatch, initial]);
    return (
        <div className="relative w-full">
            <SelectPro
                onKeyDown={onKeyDown}
                label={label}
                value={state.currentValue}
                includePlaceholderOption
                placeholderStr=""
                onChange={onChange}
                onBlur={onBlur}
                options={stateOptions}
                disabled={state.mode === "pending" || disabled}
            />
            {state.mode === "pending" ? (
                <div className="absolute bottom-0 right-0 py-3 px-8 flex items-center pointer-events-none">
                    <Spinner />
                </div>
            ) : null}
        </div>
    );
};
