import { AnswerEntityId } from "@sp-crm/core";
import { MessageCompose } from "components/messages/message-compose";
import { AdvancedSearchEntityType } from "generated/graphql";
import React from "react";

interface EmailLinkProps {
    email: string | null;
    entityType: AdvancedSearchEntityType;
    entityId: AnswerEntityId;
}

export const EmailLink: React.FC<EmailLinkProps> = props => {
    const { email, entityId, entityType } = props;
    const [showModal, setShowModal] = React.useState(false);

    const handleClick = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            setShowModal(true);
        },
        [],
    );

    const handleDismiss = React.useCallback(() => {
        setShowModal(false);
    }, []);

    if (email) {
        return (
            <div>
                <a className="break-all" href="#" onClick={handleClick}>
                    {email}
                </a>
                {showModal ? (
                    <MessageCompose
                        entityId={entityId}
                        entityType={entityType}
                        onDismiss={handleDismiss}
                        initialRecipients={[email]}
                    />
                ) : null}
            </div>
        );
    }

    return null;
};
