import { IBridgeSelect } from "@sp-crm/core";
import {
    FieldPickerField,
    getNormalizedFields,
} from "components/reports/show/custom-reports/custom-reports-helpers";
import { BridgeEntityResult } from "generated/graphql";
import React, { useMemo } from "react";
import { EntitySearchableFieldList } from "./entity-searchable-field-list";
import { RelationDescription } from "./types";

interface EntityNestedFieldPickerProps {
    entityMetadata: BridgeEntityResult;
    value: IBridgeSelect;
    onChange(value: IBridgeSelect): void;
    exclude?: (IBridgeSelect | RelationDescription)[];
    includeQuestions?: boolean;
    label?: string;
    entities: BridgeEntityResult[];
}

export const EntityNestedFieldPicker: React.FC<EntityNestedFieldPickerProps> = props => {
    const { entityMetadata, value, onChange, exclude, entities } = props;

    const includeQuestions = props.includeQuestions ?? true;

    const [searchText, setSearchText] = React.useState<string>("");

    const [fieldGroups, _normalizedFields] = useMemo((): [
        Map<string, FieldPickerField[]>,
        FieldPickerField[],
    ] => {
        return getNormalizedFields(
            entityMetadata,
            entities,
            value,
            exclude,
            searchText,
            includeQuestions,
        );
    }, [entityMetadata, exclude, value, searchText, includeQuestions, entities]);

    return (
        <EntitySearchableFieldList
            fieldGroups={fieldGroups}
            onSelected={field => {
                onChange({
                    questionId: field.questionId,
                    fieldName: field.fieldName,
                });
            }}
            searchText={searchText}
            onSearchTextChange={setSearchText}
        />
    );
};
