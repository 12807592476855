import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { EmailTemplateId, PlaceholderParser, Placeholders } from "@sp-crm/core";
import { SearchInput } from "components/ui/search-input";
import { secondaryClasses } from "components/ui/secondary-button";
import { Spinner } from "components/ui/spinner";
import { produce } from "immer";
import React from "react";
import { EditorAdapter } from "roosterjs-editor-adapter";
import { InsertTemplateIcon, TriangleDownIcon } from "./ribbon-icons";
import { Template } from "./template";

interface TemplateMenuProps {
    templates?: Template[];
    editor: EditorAdapter;
    onTemplateInserted?: (template: Template) => void;
    tokenReplacements?: Record<string, string>;
    onTokenInserted?: (tokenValue: string) => boolean;
}

interface TemplateMenuState {
    closeMenu: (() => void) | null;
    waitingForTokenReplacements: string[] | null;
    waitingTemplate: Template | null;
}

type TemplateMenuAction =
    | {
          type: "waitForTokens";
          template: Template;
          tokenValues: string[];
          closeMenu: () => void;
      }
    | {
          type: "bodyInserted";
      };

const initialState: TemplateMenuState = {
    closeMenu: null,
    waitingForTokenReplacements: null,
    waitingTemplate: null,
};

const reducer: React.Reducer<TemplateMenuState, TemplateMenuAction> = (
    state,
    action,
): TemplateMenuState => {
    return produce(state, draft => {
        switch (action.type) {
            case "waitForTokens":
                draft.waitingForTokenReplacements = action.tokenValues;
                draft.waitingTemplate = action.template;
                draft.closeMenu = action.closeMenu;
                break;
            case "bodyInserted":
                draft.waitingForTokenReplacements = null;
                draft.waitingTemplate = null;
                draft.closeMenu = null;
                break;
        }
    });
};

const replaceTemplateContent = (
    template: Template,
    tokenReplacements: Placeholders,
): Template => {
    return produce(template, draft => {
        draft.body = new PlaceholderParser(draft.body).apply(tokenReplacements);
        draft.subject = new PlaceholderParser(draft.subject).apply(tokenReplacements);
    });
};

export const TemplateMenu: React.FC<TemplateMenuProps> = props => {
    const { templates, editor, onTemplateInserted, tokenReplacements, onTokenInserted } =
        props;

    const [templateFilter, setTemplateFilter] = React.useState<string>("");

    const [state, dispatch] = React.useReducer(reducer, initialState);

    const insertTemplateContent = React.useCallback(
        (template: Template, closeMenu: () => void) => {
            // FIXME: Rooster on React18 will not render the new content unless this timeout is here.
            setTimeout(() => {
                editor.insertContent(template.body);
                if (onTemplateInserted) {
                    onTemplateInserted(template);
                }
                closeMenu();
                setTemplateFilter("");
                dispatch({ type: "bodyInserted" });
            }, 0);
        },
        [editor, onTemplateInserted, dispatch],
    );

    const handleTemplate = React.useCallback(
        (templateId: EmailTemplateId, closeMenu: () => void) => {
            if (templateId && editor) {
                const template = (templates || []).find(t => t.id === templateId) || null;
                if (template) {
                    if (tokenReplacements && onTokenInserted) {
                        const replaceableTokens = [
                            ...new PlaceholderParser(template.body).keys,
                            ...new PlaceholderParser(template.subject).keys,
                        ].filter(t => onTokenInserted(t));
                        if (
                            replaceableTokens.length > 0 &&
                            replaceableTokens.some(
                                t => typeof tokenReplacements[t] !== "string",
                            )
                        ) {
                            dispatch({
                                type: "waitForTokens",
                                template,
                                tokenValues: replaceableTokens,
                                closeMenu,
                            });
                        } else {
                            insertTemplateContent(
                                replaceTemplateContent(template, tokenReplacements),
                                closeMenu,
                            );
                        }
                    } else {
                        insertTemplateContent(template, closeMenu);
                    }
                }
            }
        },
        [
            editor,
            templates,
            tokenReplacements,
            insertTemplateContent,
            dispatch,
            onTokenInserted,
        ],
    );

    React.useEffect(() => {
        if (
            state.waitingForTokenReplacements?.length > 0 &&
            state.waitingForTokenReplacements.every(
                t => typeof tokenReplacements[t] === "string",
            ) &&
            state.waitingTemplate &&
            state.closeMenu
        ) {
            insertTemplateContent(
                replaceTemplateContent(state.waitingTemplate, tokenReplacements),
                state.closeMenu,
            );
        }
    }, [tokenReplacements]); // eslint-disable-line react-hooks/exhaustive-deps

    const filteredTemplates = React.useMemo(() => {
        return templates?.filter(t =>
            t.description.toLowerCase().includes(templateFilter.toLowerCase()),
        );
    }, [templates, templateFilter]);

    if (templates && templates.length > 0) {
        return (
            <Menu as="div" className="relative inline-block text-left ml-4">
                {({ open }) => (
                    <>
                        <MenuButton className={secondaryClasses}>
                            <div className="flex items-center space-x-2">
                                <InsertTemplateIcon className="w-5 h-5" />
                                <TriangleDownIcon className="w-1.5 h-1.5" />
                            </div>
                        </MenuButton>
                        {open ? (
                            <MenuItems
                                static
                                className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                                <p className="p-2 text-brand-700">Insert template</p>
                                {templates.length > 5 ? (
                                    <SearchInput
                                        value={templateFilter}
                                        placeholder="Filter templates"
                                        onChange={e => setTemplateFilter(e.target.value)}
                                    />
                                ) : null}
                                {filteredTemplates.map(t => (
                                    <MenuItem key={t.id}>
                                        {({ focus, close }) => (
                                            <div
                                                className={`${
                                                    focus
                                                        ? "bg-brand-100 text-brand-900"
                                                        : "text-gray-700"
                                                } group flex items-center px-4 py-2 text-sm rounded-md`}
                                                onClick={() =>
                                                    handleTemplate(t.id, close)
                                                }>
                                                {t.description}
                                            </div>
                                        )}
                                    </MenuItem>
                                ))}
                                {state.waitingForTokenReplacements ? (
                                    <div className="absolute inset-0 background-gray-500 opacity-25 flex items-center justify-center">
                                        <Spinner />
                                    </div>
                                ) : null}
                            </MenuItems>
                        ) : null}
                    </>
                )}
            </Menu>
        );
    }

    return null;
};
