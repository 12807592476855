import React, { Reducer } from "react";
import { Checkbox } from "./checkbox";
import { Spinner } from "./spinner";

interface ComponentState {
    upstreamValue: boolean;
    mode: "pristine" | "pending";
    currentValue: boolean;
}

interface Props {
    label?: string | JSX.Element;
    initial: boolean;
    onCommit: (newValue: boolean) => void;
    className?: string;
    disabled?: boolean;
}

type Action =
    | { type: "change"; value: boolean }
    | { type: "updateUpstream"; value: boolean };

const invalidAction = (action: Action): never => {
    throw new Error(`unknown action type ${action.type}`);
};
const invalidMode = (mode: ComponentState["mode"]): never => {
    throw new Error(`unknown mode ${mode}`);
};

const reducer: Reducer<ComponentState, Action> = (p, a): ComponentState => {
    switch (a.type) {
        case "change":
            return { ...p, mode: "pending", currentValue: a.value };
        case "updateUpstream":
            switch (p.mode) {
                case "pending":
                    return {
                        ...p,
                        mode: p.currentValue === a.value ? "pristine" : "pending",
                        upstreamValue: a.value,
                    };
                case "pristine":
                    return { ...p, currentValue: a.value, upstreamValue: a.value };
            }
            return invalidMode(p.mode);
    }
    return invalidAction(a);
};

export const AutosavingCheckbox: React.FC<Props> = props => {
    const { initial, onCommit, label, className, disabled } = props;
    const [state, dispatch] = React.useReducer(reducer, {
        upstreamValue: initial,
        currentValue: initial,
        mode: "pristine",
    });
    const mode = state.mode;
    const onChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (mode === "pristine") {
                dispatch({ type: "change", value: e.target.checked });
                onCommit(e.target.checked);
            }
        },
        [dispatch, onCommit, mode],
    );

    React.useEffect(() => {
        dispatch({ type: "updateUpstream", value: initial });
    }, [dispatch, initial]);

    return (
        <div className={`${className ?? ""} w-full`}>
            <div className="relative">
                <Checkbox
                    disabled={mode === "pending" || disabled}
                    label={label}
                    checked={state.currentValue}
                    onChange={onChange}
                />
                {mode === "pending" ? (
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                        <Spinner />
                    </div>
                ) : null}
            </div>
        </div>
    );
};
