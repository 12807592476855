import {
    AnswerEntityId,
    ClientId,
    CommunityId,
    ContactId,
    RegionId,
    genEntityId,
} from "@sp-crm/core";
import { SupportEmailLink } from "components/app/support-email-link";
import { PowerOfAttorneySelect } from "components/clients/show-client/power-of-attorney-select";
import { Feature } from "components/feature";
import { Header } from "components/header";
import { LocaleContext } from "components/locale-provider/locale-provider";
import { PhoneInput } from "components/shared/phone-input";
import { AutosavingCheckbox } from "components/ui/autosaving-checkbox";
import { AutosavingInput } from "components/ui/autosaving-input";
import { AutosavingStateInput } from "components/ui/autosaving-state-input";
import { InlineBanner } from "components/ui/inline-banner";
import { PrimaryButton } from "components/ui/primary-button";
import { Spinner } from "components/ui/spinner";
import {
    AdvancedSearchEntityType,
    ClientContactRelationship,
    ContactWithLinksFragment,
    PatchAttachedReferenceContactInput,
    PatchContactInput,
    useCreateContactMutation,
    useGetContactQuery,
    usePatchContactMutation,
} from "generated/graphql";
import { produce } from "immer";
import React, { useCallback, useEffect } from "react";
import { useRegionId } from "store/selectors/hooks";

interface ContactEditorProps {
    initialContactId: ContactId;
    parentEntityType: AdvancedSearchEntityType;
    parentEntityId: AnswerEntityId;
    clientContactRelationship?: ClientContactRelationship;
    onSaved: (contact: ContactWithLinksFragment) => void;
    onClose: () => void;
}

type FieldUpdate =
    | {
          type: "contact";
          patch: Partial<PatchContactInput>;
      }
    | {
          type: "communityContact";
          communityId: CommunityId;
          patch: Partial<PatchContactInput["communityContacts"][0]>;
      }
    | {
          type: "referralContact";
          patch: Partial<PatchAttachedReferenceContactInput>;
      }
    | {
          type: "clientContact";
          clientId: ClientId;
          relationship: ClientContactRelationship;
          patch: Partial<PatchContactInput["clientContacts"][0]>;
      };

interface ComponentState {
    status: "loading" | "saving" | "loaded" | "failed";
    contact: ContactWithLinksFragment | null;
    pendingUpdates: FieldUpdate[];
    hasSaved: boolean;
    contactExists: boolean;
    closeRequested: boolean;
    errorMessage: string;
}

const defaultErrorMessage = "The contact failed to save.";

type Action =
    | { type: "setContact"; contact: ContactWithLinksFragment }
    | { type: "enqueueUpdate"; fieldUpdates: FieldUpdate[] }
    | { type: "startPatch" }
    | { type: "startCreate" }
    | { type: "ensureReferralContact"; regionId: RegionId }
    | { type: "clearReferralContacts" }
    | { type: "requestClose" }
    | { type: "setError"; message: string };

const reducer: React.Reducer<ComponentState, Action> = (p, a): ComponentState => {
    return produce(p, draft => {
        switch (a.type) {
            case "setContact": {
                draft.contact = a.contact;
                draft.status = "loaded";
                draft.contactExists = true;
                break;
            }
            case "enqueueUpdate": {
                draft.pendingUpdates.push(...a.fieldUpdates);
                break;
            }
            case "startPatch": {
                draft.status = "saving";
                draft.pendingUpdates = [];
                draft.hasSaved = true;
                break;
            }
            case "startCreate": {
                draft.status = "saving";
                draft.hasSaved = true;
                break;
            }
            case "ensureReferralContact": {
                if (draft.contact) {
                    draft.contact.referenceContacts.push(
                        emptyReferenceContact(a.regionId),
                    );
                }
                break;
            }
            case "clearReferralContacts": {
                if (draft.contact) {
                    draft.contact.referenceContacts = [];
                }
                break;
            }
            case "requestClose": {
                draft.closeRequested = true;
                break;
            }
            case "setError": {
                draft.status = "failed";
                draft.errorMessage = a.message || defaultErrorMessage;
                break;
            }
        }
    });
};

const emptyReferenceContact = (
    regionId: RegionId,
): ContactWithLinksFragment["referenceContacts"][0] => {
    return {
        dateAdded: new Date().toISOString(),
        createdBy: null,
        id: genEntityId(),
        updatedAt: new Date().toISOString(),
        updatedBy: null,
        regionId,
        appLink: "",
    };
};

const emptyContact = (
    props: ContactEditorProps,
    regionId: RegionId,
): ContactWithLinksFragment => {
    const id = genEntityId<ContactId>();

    const communityContacts: ContactWithLinksFragment["communityContacts"] =
        props.parentEntityType === AdvancedSearchEntityType.Community
            ? [
                  {
                      communityId: props.parentEntityId as CommunityId,
                      contactId: id,
                      primary: true,
                      community: {
                          id: props.parentEntityId as CommunityId,
                          appLink: "",
                      },
                  },
              ]
            : [];

    const referenceContacts: ContactWithLinksFragment["referenceContacts"] =
        props.parentEntityType === AdvancedSearchEntityType.Community
            ? [emptyReferenceContact(regionId)]
            : [];

    const clientContacts: ContactWithLinksFragment["clientContacts"] =
        props.parentEntityType === AdvancedSearchEntityType.Client
            ? [
                  {
                      clientId: props.parentEntityId as ClientId,
                      contactId: id,
                      relationship: props.clientContactRelationship,
                      powerOfAttorney: [],
                      client: {
                          id: props.parentEntityId as ClientId,
                          appLink: "",
                      },
                  },
              ]
            : [];

    return {
        id,
        email1OptOut: false,
        clientContacts,
        communityContacts,
        referenceContacts,
    };
};

const initialState = (props: ContactEditorProps, regionId: RegionId): ComponentState => {
    return {
        status: props.initialContactId ? "loading" : "loaded",
        contact: props.initialContactId ? null : emptyContact(props, regionId),
        pendingUpdates: [],
        contactExists: false,
        hasSaved: false,
        closeRequested: false,
        errorMessage: "",
    };
};

export const ContactEditor: React.FC<ContactEditorProps> = props => {
    const {
        initialContactId,
        parentEntityId,
        parentEntityType,
        onSaved,
        onClose,
        clientContactRelationship,
    } = props;
    const regionId = useRegionId();

    const [state, dispatch] = React.useReducer(reducer, initialState(props, regionId));

    const contactQuery = useGetContactQuery(
        { contactId: initialContactId },
        { enabled: false },
    );

    const createContact = useCreateContactMutation();
    const patchContact = usePatchContactMutation();

    useEffect(() => {
        if (initialContactId) {
            contactQuery.refetch();
        }
    }, [initialContactId]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (contactQuery.data?.getContact) {
            dispatch({ type: "setContact", contact: contactQuery.data.getContact });
        }
    }, [contactQuery.data?.getContact]);

    const doPatch = useCallback(
        async (contactId: ContactId, updatesToMake: FieldUpdate[]) => {
            dispatch({ type: "startPatch" });

            const params: PatchContactInput = updatesToMake.reduce(
                (agg: PatchContactInput, updateToMake) => {
                    switch (updateToMake.type) {
                        case "contact":
                            return {
                                ...agg,
                                ...updateToMake.patch,
                            };
                        case "communityContact":
                            return {
                                ...agg,
                                communityContacts: [
                                    ...(agg.communityContacts || []),
                                    {
                                        communityId: updateToMake.communityId,
                                        ...updateToMake.patch,
                                    },
                                ],
                            };
                        case "clientContact":
                            return {
                                ...agg,
                                clientContacts: [
                                    ...(agg.clientContacts || []),
                                    {
                                        clientId: updateToMake.clientId,
                                        relationship: updateToMake.relationship,
                                        ...updateToMake.patch,
                                    },
                                ],
                            };
                        case "referralContact":
                            return {
                                ...agg,
                                referenceContacts: [
                                    ...(agg.referenceContacts || []),
                                    {
                                        regionId,
                                        ...updateToMake.patch,
                                    },
                                ],
                            };
                    }
                },
                { id: contactId },
            );
            try {
                const patched = await patchContact.mutateAsync({ params });
                dispatch({ type: "setContact", contact: patched.patchContact });
            } catch (e) {
                const message = e?.message || defaultErrorMessage;
                dispatch({ type: "setError", message });
            }
        },
        [patchContact, dispatch, regionId],
    );

    const handleFieldUpdates = useCallback(
        async (fieldUpdates: FieldUpdate[]) => {
            if (state.status === "saving") {
                dispatch({ type: "enqueueUpdate", fieldUpdates });
            } else if (state.status === "loaded") {
                if (state.contactExists) {
                    const updatesToMake = [...state.pendingUpdates, ...fieldUpdates];
                    await doPatch(state.contact.id, updatesToMake);
                } else {
                    dispatch({ type: "startCreate" });
                    dispatch({ type: "enqueueUpdate", fieldUpdates });

                    try {
                        const created = await createContact.mutateAsync({
                            params: {
                                linkedEntityId: parentEntityId,
                                linkedEntityType: parentEntityType,
                                referenceContactParams:
                                    state.contact.referenceContacts.length > 0 ||
                                    fieldUpdates.some(s => s.type === "referralContact")
                                        ? {
                                              regionId,
                                          }
                                        : null,
                                clientContactParams: clientContactRelationship
                                    ? { relationship: clientContactRelationship }
                                    : null,
                            },
                        });
                        dispatch({ type: "setContact", contact: created.createContact });
                    } catch (e) {
                        const message = e?.message || defaultErrorMessage;
                        dispatch({ type: "setError", message });
                    }
                }
            }
        },
        [
            state.status,
            state.contact,
            state.contactExists,
            state.pendingUpdates,
            parentEntityId,
            parentEntityType,
            createContact,
            regionId,
            clientContactRelationship,
            doPatch,
            dispatch,
        ],
    );

    useEffect(() => {
        if (state.status === "loaded" && state.contact && state.hasSaved) {
            if (state.pendingUpdates.length > 0) {
                doPatch(state.contact.id, state.pendingUpdates);
            } else {
                onSaved(state.contact);
            }
        }
    }, [state.contact]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleClose = React.useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();
            dispatch({ type: "requestClose" });
        },
        [dispatch],
    );

    const handleReferenceContactChecked = React.useCallback(
        (checked: boolean) => {
            if (state.status === "loaded" && !state.contactExists) {
                if (checked) {
                    dispatch({ type: "ensureReferralContact", regionId });
                } else {
                    dispatch({
                        type: "clearReferralContacts",
                    });
                }
            } else {
                handleFieldUpdates([
                    {
                        type: "referralContact",
                        patch: checked
                            ? {
                                  deleted: false,
                                  communityId: parentEntityId as CommunityId,
                                  summary: state.contact?.contactNotes || "",
                              }
                            : { deleted: true },
                    },
                ]);
            }
        },
        [
            state.status,
            state.contactExists,
            state.contact?.contactNotes,
            parentEntityId,
            handleFieldUpdates,
            regionId,
            dispatch,
        ],
    );

    useEffect(() => {
        if (
            state.closeRequested &&
            state.status === "loaded" &&
            state.pendingUpdates.length === 0
        ) {
            onClose();
        }

        if (state.closeRequested && state.status === "failed") {
            onClose();
        }
    }, [state.closeRequested, state.status, state.pendingUpdates]); // eslint-disable-line react-hooks/exhaustive-deps

    const showRole = parentEntityType === AdvancedSearchEntityType.Community;
    const showAddress = parentEntityType === AdvancedSearchEntityType.Client;
    const allowReferralContactTracking =
        parentEntityType === AdvancedSearchEntityType.Community;
    const showPrimaryContact = parentEntityType === AdvancedSearchEntityType.Community;
    const isMoreContact =
        parentEntityType === AdvancedSearchEntityType.Client &&
        clientContactRelationship === ClientContactRelationship.MoreContact;
    const isCareProvider =
        parentEntityType === AdvancedSearchEntityType.Client &&
        clientContactRelationship === ClientContactRelationship.CareProvider;
    const showPowerOfAttorney = isMoreContact;
    const showRelationship = isMoreContact;
    const showProviderType = isCareProvider;
    const showBusinessName = isCareProvider;
    const showCellPhone = !isCareProvider;
    const showFax = isCareProvider;

    const matchingCommunityContact =
        state.contact?.communityContacts.find(cc => cc.communityId === parentEntityId) ||
        null;

    const matchingClientContact =
        state.contact?.clientContacts.find(
            cc =>
                cc.clientId === parentEntityId &&
                cc.relationship === clientContactRelationship,
        ) || null;

    const matchingReferenceContact = state.contact?.referenceContacts.find(
        rc => rc.regionId === regionId,
    );

    if (state.status === "loading") {
        return <Spinner />;
    }

    return (
        <div className="space-y-2 md:space-y-4">
            {allowReferralContactTracking ? (
                <AutosavingCheckbox
                    label="Track this contact as a referral contact"
                    initial={!!matchingReferenceContact}
                    onCommit={handleReferenceContactChecked}
                />
            ) : null}
            <Header iconName="08_ContactPerson">
                {state.contact?.name || "(no name)"}
            </Header>
            <div className="sm:flex sm:space-x-1 md:space-x-2 lg:space-x-4">
                <AutosavingInput
                    label="Name"
                    initial={state.contact?.name}
                    onCommit={newValue =>
                        handleFieldUpdates([
                            {
                                type: "contact",
                                patch: { name: newValue },
                            },
                        ])
                    }
                />
                {showRole ? (
                    <AutosavingInput
                        label="Role / title"
                        initial={state.contact?.role}
                        onCommit={newValue =>
                            handleFieldUpdates([
                                {
                                    type: "contact",
                                    patch: { role: newValue },
                                },
                            ])
                        }
                    />
                ) : null}
                {showRelationship ? (
                    <AutosavingInput
                        label="Relationship"
                        initial={state.contact?.relationship}
                        onCommit={newValue =>
                            handleFieldUpdates([
                                {
                                    type: "contact",
                                    patch: { relationship: newValue },
                                },
                            ])
                        }
                    />
                ) : null}
                {showProviderType ? (
                    <AutosavingInput
                        label="Type of provider"
                        initial={state.contact?.relationship}
                        onCommit={newValue =>
                            handleFieldUpdates([
                                {
                                    type: "contact",
                                    patch: { relationship: newValue },
                                },
                            ])
                        }
                    />
                ) : null}
            </div>
            {showBusinessName ? (
                <AutosavingInput
                    label="Clinic / business name (if applicable)"
                    initial={state.contact?.role}
                    onCommit={newValue =>
                        handleFieldUpdates([
                            {
                                type: "contact",
                                patch: { role: newValue },
                            },
                        ])
                    }
                />
            ) : null}
            <div className="sm:flex sm:space-x-1 md:space-x-2 lg:space-x-4">
                {showCellPhone ? (
                    <PhoneInput
                        label="Cell phone number"
                        initial={state.contact?.cellPhone}
                        onCommit={newValue =>
                            handleFieldUpdates([
                                {
                                    type: "contact",
                                    patch: { cellPhone: newValue },
                                },
                            ])
                        }
                    />
                ) : null}
                <PhoneInput
                    label={showCellPhone ? "Phone number (other)" : "Phone number"}
                    initial={state.contact?.phone1}
                    onCommit={newValue =>
                        handleFieldUpdates([
                            {
                                type: "contact",
                                patch: { phone1: newValue },
                            },
                        ])
                    }
                />
                {showFax ? (
                    <PhoneInput
                        label="Fax number"
                        initial={state.contact?.fax}
                        onCommit={newValue =>
                            handleFieldUpdates([
                                {
                                    type: "contact",
                                    patch: { fax: newValue },
                                },
                            ])
                        }
                    />
                ) : null}
            </div>
            <div>
                <AutosavingInput
                    label="Email"
                    initial={state.contact?.email1}
                    onCommit={newValue =>
                        handleFieldUpdates([
                            {
                                type: "contact",
                                patch: { email1: newValue },
                            },
                        ])
                    }
                />
            </div>
            <Feature name="emailoptout">
                <div>
                    <AutosavingCheckbox
                        initial={!!state.contact?.email1OptOut}
                        label="Email Opt-out (for bulk/newsletter emails)"
                        onCommit={newValue =>
                            handleFieldUpdates([
                                {
                                    type: "contact",
                                    patch: { email1OptOut: newValue },
                                },
                            ])
                        }
                    />
                </div>
            </Feature>
            {showAddress ? (
                <LocaleContext.Consumer>
                    {locale => (
                        <div className="space-y-1 md:space-y-2">
                            <div>
                                <AutosavingInput
                                    label="Address"
                                    initial={state.contact?.address1}
                                    onCommit={newVal =>
                                        handleFieldUpdates([
                                            {
                                                type: "contact",
                                                patch: { address1: newVal },
                                            },
                                        ])
                                    }
                                />
                            </div>
                            <div className="sm:flex sm:space-x-1 md:space-x-2 lg:space-x-4">
                                <AutosavingInput
                                    label="City"
                                    initial={state.contact?.city}
                                    onCommit={newVal =>
                                        handleFieldUpdates([
                                            {
                                                type: "contact",
                                                patch: { city: newVal },
                                            },
                                        ])
                                    }
                                />
                                <AutosavingStateInput
                                    label={locale.strings.state}
                                    initial={state.contact?.state}
                                    onCommit={newVal =>
                                        handleFieldUpdates([
                                            {
                                                type: "contact",
                                                patch: { state: newVal },
                                            },
                                        ])
                                    }
                                />
                                <AutosavingInput
                                    label={locale.strings.zip}
                                    initial={state.contact?.zip}
                                    onCommit={newVal =>
                                        handleFieldUpdates([
                                            {
                                                type: "contact",
                                                patch: { zip: newVal },
                                            },
                                        ])
                                    }
                                />
                            </div>
                        </div>
                    )}
                </LocaleContext.Consumer>
            ) : null}
            {matchingReferenceContact ? (
                <div>
                    <AutosavingInput
                        label="Summary"
                        multiLine={true}
                        initial={matchingReferenceContact.summary}
                        onCommit={newVal =>
                            handleFieldUpdates([
                                {
                                    type: "referralContact",
                                    patch: { summary: newVal },
                                },
                                {
                                    type: "contact",
                                    patch: { contactNotes: newVal },
                                },
                            ])
                        }
                    />
                </div>
            ) : (
                <div>
                    <AutosavingInput
                        label={"Contact notes / details"}
                        multiLine={true}
                        initial={state.contact?.contactNotes}
                        onCommit={newVal =>
                            handleFieldUpdates([
                                {
                                    type: "contact",
                                    patch: { contactNotes: newVal },
                                },
                            ])
                        }
                    />
                </div>
            )}
            {showPrimaryContact ? (
                <div>
                    <AutosavingCheckbox
                        label="Primary contact"
                        initial={!!matchingCommunityContact?.primary}
                        onCommit={newValue =>
                            handleFieldUpdates([
                                {
                                    type: "communityContact",
                                    communityId: parentEntityId as CommunityId,
                                    patch: { primary: newValue },
                                },
                            ])
                        }
                    />
                </div>
            ) : null}
            {showPowerOfAttorney ? (
                <PowerOfAttorneySelect
                    value={matchingClientContact?.powerOfAttorney || []}
                    onChange={newVal =>
                        handleFieldUpdates([
                            {
                                type: "clientContact",
                                clientId: parentEntityId as ClientId,
                                relationship: clientContactRelationship,
                                patch: { powerOfAttorney: newVal },
                            },
                        ])
                    }
                />
            ) : null}
            <div className="flex justify-end items-center space-x-2">
                {state.closeRequested ? <Spinner /> : null}
                <PrimaryButton onClick={handleClose} disabled={state.closeRequested}>
                    Close
                </PrimaryButton>
            </div>
            {state.errorMessage ? (
                <InlineBanner type="error">
                    <p>The contact did not save for this reason: {state.errorMessage}</p>
                    <p>
                        Refresh your browser and try again. If this issue persists,
                        contact <SupportEmailLink />
                    </p>
                </InlineBanner>
            ) : null}
        </div>
    );
};
