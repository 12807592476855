import { Select } from "components/ui/select";
import { AdvancedSearchEntityType, BridgeEntityResult } from "generated/graphql";
import React, { useCallback } from "react";

interface EntityTypeEditorProps {
    entityType: AdvancedSearchEntityType;
    onChange: (entityType: AdvancedSearchEntityType) => void;
    entities: BridgeEntityResult[];
    allowedEntityTypes?: AdvancedSearchEntityType[];
    textSize?: "sm";
    preLabel?: string;
    postLabel?: string;
}

export const EntityTypeEditor: React.FC<EntityTypeEditorProps> = props => {
    const {
        entityType,
        onChange,
        entities,
        allowedEntityTypes,
        textSize,
        preLabel,
        postLabel,
    } = props;

    const onDropdownChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            onChange(e.target.value as AdvancedSearchEntityType);
        },
        [onChange],
    );

    const selectableEntityTypes =
        allowedEntityTypes ||
        Object.keys(AdvancedSearchEntityType).filter(t =>
            entities.some(e => e.name === t),
        );

    const textSizeClass = textSize === "sm" ? "text-sm" : "";

    return selectableEntityTypes.length > 1 ? (
        <div className={`flex items-center space-x-2`}>
            {preLabel ? <p className={textSizeClass}>{preLabel}</p> : null}
            <Select
                value={entityType}
                onChange={onDropdownChange}
                textSize={textSize}
                disabled={selectableEntityTypes.length <= 1}>
                {selectableEntityTypes
                    .filter(key => !!entities.find(e => e.name === key))
                    .map(key => (
                        <option key={key} value={key}>
                            {entities.find(e => e.name === key)?.title || key}
                        </option>
                    ))}
            </Select>
            {postLabel ? <p className={textSizeClass}>{postLabel}</p> : null}
        </div>
    ) : (
        <p>
            {[
                preLabel,
                entities.find(e => e.name === selectableEntityTypes[0])?.title ||
                    selectableEntityTypes[0] ||
                    "",
                postLabel,
            ].join(" ")}
        </p>
    );
};
