import {
    Dialog,
    DialogPanel,
    DialogTitle,
    Transition,
    TransitionChild,
} from "@headlessui/react";
import { QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import React from "react";
import ReactDOM from "react-dom/client";
import { Input } from "./input";
import { PrimaryButton } from "./primary-button";
import { SecondaryButton } from "./secondary-button";

interface FieldDefinition {
    name: string;
    label: string;
    placeholder?: string;
}

// Button definition interface
interface ButtonDefinition<Name extends string> {
    text: string;
    callbackName: Name;
    primary?: boolean;
}

// Component props interface
interface MultiFieldDialogProps<ValuesType, ButtonNameType extends string> {
    title: string;
    fields: FieldDefinition[];
    currentValues: ValuesType;
    buttons: ButtonDefinition<ButtonNameType>[];
    resolve: (result: { values: ValuesType; button: ButtonNameType }) => void;
}

const MultiFieldDialog = <ValuesType, ButtonNameType extends string>(
    props: MultiFieldDialogProps<ValuesType, ButtonNameType>,
) => {
    const { title, fields, currentValues, buttons, resolve } = props;

    const [isHidden, setIsHidden] = React.useState(false);
    const [values, setValues] = React.useState<ValuesType>(currentValues);

    const handleChange = (name: string, value: string) => {
        setValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleButtonClick = (button: ButtonDefinition<ButtonNameType>) => {
        resolve({ values, button: button.callbackName });
        setIsHidden(true);
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
    };

    return (
        <Transition show={!isHidden} appear={true}>
            <Dialog as="div" className="relative z-1500" onClose={() => {}}>
                <TransitionChild
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed z-1500 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <TransitionChild
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                            <DialogPanel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                                <form onSubmit={handleSubmit}>
                                    <div className="sm:flex sm:items-start">
                                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-brand-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <QuestionMarkCircleIcon
                                                className="h-6 w-6 text-brand-600"
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-1">
                                            <DialogTitle
                                                as="h3"
                                                className="text-lg leading-6 font-medium text-gray-900">
                                                {title}
                                            </DialogTitle>
                                            <div className="mt-2 space-y-2">
                                                {fields.map(field => (
                                                    <Input
                                                        key={field.name}
                                                        value={(
                                                            values[
                                                                field.name as keyof ValuesType
                                                            ] || ""
                                                        ).toString()}
                                                        onChange={e =>
                                                            handleChange(
                                                                field.name,
                                                                e.target.value,
                                                            )
                                                        }
                                                        label={field.label}
                                                        placeholder={field.placeholder}
                                                        type="text"
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5 sm:mt-6 sm:flex sm:flex-row-reverse">
                                        {buttons.map(button => {
                                            const ButtonComponent = button.primary
                                                ? PrimaryButton
                                                : SecondaryButton;
                                            return (
                                                <ButtonComponent
                                                    type={
                                                        button.primary
                                                            ? "submit"
                                                            : "button"
                                                    }
                                                    key={button.callbackName}
                                                    onClick={() =>
                                                        handleButtonClick(button)
                                                    }
                                                    className="ml-3">
                                                    {button.text}
                                                </ButtonComponent>
                                            );
                                        })}
                                    </div>
                                </form>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
};

export const multiFieldPrompt = async <
    ValuesType,
    ButtonNameType extends string,
>(options: {
    title: string;
    fields: FieldDefinition[];
    currentValues: ValuesType;
    buttons: ButtonDefinition<ButtonNameType>[];
}): Promise<{ values: ValuesType; button: ButtonNameType }> => {
    const wrapper = document.body.appendChild(document.createElement("div"));
    const root = ReactDOM.createRoot(wrapper);
    const result = await new Promise<{ values: ValuesType; button: ButtonNameType }>(
        resolve => {
            root.render(
                <MultiFieldDialog
                    title={options.title}
                    fields={options.fields}
                    currentValues={options.currentValues}
                    buttons={options.buttons}
                    resolve={resolve}
                />,
            );
        },
    );
    root.unmount();
    setTimeout(() => wrapper.remove());
    return result;
};
