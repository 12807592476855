import { IBridgeSelect, QuestionId } from "@sp-crm/core";

export interface EntityConditionFieldPicker {
    fieldName?: string;
    questionId?: QuestionId;
    relation?: {
        name: string;
    };
}

export interface RelationDescription {
    prefix: string;
}

export const isRelationDescription = (
    value: RelationDescription | IBridgeSelect,
): value is RelationDescription => {
    return value && !!(value as RelationDescription).prefix;
};
