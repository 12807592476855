import {
    AnswerEntityId,
    IContact,
    LayoutIcon,
    ReferralMappings,
    formatPhoneNumber,
} from "@sp-crm/core";
import { Icon } from "components/icon";
import { AdvancedSearchEntityType } from "generated/graphql";
import * as _ from "lodash";
import * as React from "react";
import { Link } from "react-router-dom";
import { EmailLink } from "util/email-link";
import { displayableUri, sanitizeUri } from "util/uri";
import { friendlyDisplayName } from "../../../util/text";

interface ContactCardProps {
    contact: IContact | null;
    parentEntityType: AdvancedSearchEntityType;
    parentId: AnswerEntityId;
    href?: string;
    icon?: LayoutIcon | JSX.Element | null;
    children?: React.ReactNode;
}

export class ContactCard extends React.Component<ContactCardProps, undefined> {
    name(): JSX.Element {
        return (
            <div className="text-base flex items-center space-x-2">
                {typeof this.props.icon === "string" ? (
                    <div className="w-4">
                        <Icon name={this.props.icon} />
                    </div>
                ) : (
                    this.props.icon
                )}
                {this.props.href ? (
                    <Link to={this.props.href}>
                        {friendlyDisplayName(this.props.contact.name)}
                    </Link>
                ) : (
                    <div>{friendlyDisplayName(this.props.contact.name)}</div>
                )}
            </div>
        );
    }

    role(): JSX.Element {
        if (!this.props.contact.role) {
            return null;
        }

        return <div className="font-medium mb-1">{this.props.contact.role}</div>;
    }

    relationship(): JSX.Element {
        if (!this.props.contact.relationship) {
            return null;
        }

        return <div className="font-medium mb-1">{this.props.contact.relationship}</div>;
    }

    type(): JSX.Element {
        return this.props.contact.contactTypeMaybe
            .map(ct => ReferralMappings[ct])
            .map(mapping => (
                <div key={mapping.category} className="font-medium mb-1">
                    {mapping.category} &raquo; {mapping.humanReadable}
                </div>
            ))
            .getOrElse(null);
    }

    cell(): JSX.Element {
        if (!this.props.contact.cellPhone) {
            return null;
        }

        const cell = formatPhoneNumber(this.props.contact.cellPhone);
        return (
            <div>
                Cell: <a href={`tel:${cell}`}>{cell}</a>
            </div>
        );
    }

    phone(): JSX.Element {
        if (!this.props.contact.phone1) {
            return null;
        }

        const phone = formatPhoneNumber(this.props.contact.phone1);

        return (
            <div>
                Phone: <a href={`tel:${phone}`}>{phone}</a>
            </div>
        );
    }

    fax(): JSX.Element {
        if (!this.props.contact.fax) {
            return null;
        }

        const fax = formatPhoneNumber(this.props.contact.fax);

        return (
            <div>
                Fax: <a href={`tel:${fax}`}>{fax}</a>
            </div>
        );
    }

    email(): JSX.Element {
        return (
            <EmailLink
                email={this.props.contact.email1}
                entityType={this.props.parentEntityType}
                entityId={this.props.parentId}
            />
        );
    }

    website(): JSX.Element {
        if (!this.props.contact.website) {
            return null;
        }
        return (
            <div>
                <a href={sanitizeUri(this.props.contact.website)}>
                    {displayableUri(this.props.contact.website, 48)}
                </a>
            </div>
        );
    }

    cityStateZip(): string {
        let { city, state, zip } = this.props.contact;
        city = city ?? "";
        state = state ?? "";
        zip = zip ?? "";

        if (city && state && zip) return `${city}, ${state} ${zip}`;
        if (city && state) return `${city}, ${state}`;
        if ((city || state) && zip) return `${city}${state} ${zip}`;
        if (zip) return zip;
        return null;
    }

    address(): JSX.Element {
        const c = this.props.contact;
        const fields = [c.address1, c.address2, c.city, c.state, c.zip];
        if (!_.some(fields, f => f)) {
            return null;
        }
        const address2 = this.props.contact.address2 ? (
            <span>
                <br />
                {this.props.contact.address2}
            </span>
        ) : null;
        return (
            <div className="mb-1">
                {this.props.contact.address1}
                {address2}
                <br />
                {this.cityStateZip()}
            </div>
        );
    }

    notes(): JSX.Element {
        if (!this.props.contact.contactNotes) {
            return null;
        }
        return <p className="italic mt-2">{this.props.contact.contactNotes}</p>;
    }

    render() {
        return (
            <div className="text-sm">
                {this.name()}
                {this.role()}
                {this.relationship()}
                {this.type()}
                {this.address()}
                {this.cell()}
                {this.phone()}
                {this.fax()}
                {this.email()}
                {this.website()}
                {this.notes()}
                {this.props.children}
            </div>
        );
    }
}
