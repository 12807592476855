import { displayName, isCrmAuthor, UserId } from "@sp-crm/core";
import * as React from "react";
import { useSelector } from "react-redux";
import { useProductName } from "store/selectors/branding";
import { ApplicationState } from "store/state";

interface AuthorNameProps {
    authorId: UserId;
}

export const AuthorName: React.FC<AuthorNameProps> = props => {
    const user = useSelector(
        (state: ApplicationState) => state.users.users[props.authorId],
    );
    const productName = useProductName();

    if (isCrmAuthor(props.authorId)) {
        return <span>{productName}</span>;
    }

    return <span>{displayName(user)}</span>;
};
