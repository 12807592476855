import { NoteId, UserId } from "@sp-crm/core";
import { DateInput } from "components/ui/date-input";
import { PrimaryButton } from "components/ui/primary-button";
import { SecondaryButton } from "components/ui/secondary-button";
import { TextArea } from "components/ui/textarea";
import React, { useCallback, useState } from "react";

export interface IEntityNote {
    _id: NoteId;
    text: string;
    dateAdded: Date;
    dateAddedManualOverride?: Date | null;
    authorId: UserId;
    deleted?: boolean;
    wasEdited: boolean;
}

interface EditNoteProps {
    note: IEntityNote;
    onEditNote: (noteId: NoteId, text: string, dateAdded: Date) => void;
    leaveEditMode: () => void;
}

export const EditNote: React.FC<EditNoteProps> = props => {
    const { note, onEditNote, leaveEditMode } = props;
    const [text, setText] = useState(note.text);
    const [dateAdded, setDateAdded] = useState<Date>(
        (note.dateAddedManualOverride as Date) || note.dateAdded,
    );
    const submit = useCallback(() => {
        onEditNote(note._id, text, dateAdded);
        leaveEditMode();
    }, [text, dateAdded, onEditNote, leaveEditMode, note._id]);
    return (
        <div className="space-y-2 mt-2">
            <div>
                <TextArea
                    rows={4}
                    autoGrow
                    value={text}
                    onChange={v => setText(v.target.value)}
                />
            </div>
            <div className="flex justify-between items-end space-x-4">
                <div className="flex-1">
                    <DateInput
                        value={dateAdded}
                        onChange={v => setDateAdded(v)}
                        label="Added on"
                    />
                </div>
                <div className="flex space-x-2 flex-1 justify-end">
                    <SecondaryButton onClick={leaveEditMode}>Cancel</SecondaryButton>
                    <PrimaryButton onClick={submit}>Save</PrimaryButton>
                </div>
            </div>
        </div>
    );
};
