import { locales, Localization, Maybe } from "@sp-crm/core";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { ApplicationState } from "store/state";

export const LocaleContext = React.createContext<Localization>(locales.default);

interface LocaleProviderProps {
    children: React.ReactNode;
}

export const LocaleProvider: React.FC<LocaleProviderProps> = props => {
    const localeName = useSelector((state: ApplicationState) =>
        Maybe.fromValue(state.region.selectedRegion)
            .map(key => state.region.regions.find(r => r.key === key))
            .map(r => r.locale)
            .getOrElse("default"),
    );
    const locale = locales[localeName] || locales.default;
    return (
        <LocaleContext.Provider value={locale}>{props.children}</LocaleContext.Provider>
    );
};

export const useLocalizedStrings = (): Localization => {
    return useContext(LocaleContext);
};
