import { IBridgeSelect } from "@sp-crm/core";
import { AdvancedSearchEntityType, useGetEntitiesQuery } from "generated/graphql";
import React from "react";
import { stableQueryOptions } from "util/requests";
import { EntityNestedFieldPicker } from "./entity-nested-field-picker";
import { EntityTypeEditor } from "./entity-type-editor";

interface MultiEntityFieldPickerProps {
    initialType?: AdvancedSearchEntityType | null;
    entityTypes: AdvancedSearchEntityType[];
    onSelected: (entityType: AdvancedSearchEntityType, field: IBridgeSelect) => void;
}

export const MultiEntityFieldPicker: React.FC<MultiEntityFieldPickerProps> = props => {
    const { entityTypes, onSelected, initialType } = props;
    const [selectedEntityType, setSelectedEntityType] =
        React.useState<AdvancedSearchEntityType>(initialType || entityTypes[0]);

    const getEntities = useGetEntitiesQuery({}, stableQueryOptions());

    const handlePlaceholder = React.useCallback(
        (value: IBridgeSelect) => {
            onSelected(selectedEntityType, value);
        },
        [onSelected, selectedEntityType],
    );

    if (getEntities?.data?.getEntities?.length > 0) {
        return (
            <div>
                <div className="px-4 pt-4">
                    <EntityTypeEditor
                        entities={getEntities.data.getEntities}
                        entityType={selectedEntityType}
                        onChange={setSelectedEntityType}
                        allowedEntityTypes={entityTypes}
                        textSize="sm"
                        postLabel="Placeholders"
                    />
                </div>
                <EntityNestedFieldPicker
                    value={{}}
                    onChange={handlePlaceholder}
                    entities={getEntities.data.getEntities}
                    entityMetadata={getEntities.data.getEntities.find(
                        e => e.name === selectedEntityType,
                    )}
                    includeQuestions={false}
                />
            </div>
        );
    }

    return null;
};
