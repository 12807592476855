import React, { Reducer } from "react";
import { Spinner } from "./spinner";
import { TextArea } from "./textarea";

interface ComponentState {
    upstreamValue: string;
    mode: "pristine" | "dirty" | "pending";
    currentValue: string;
}

interface Props {
    label?: string | JSX.Element;
    initial: string;
    onCommit: (newValue: string) => void;
    onEmpty?: () => string;
    multiLine?: boolean;
    /**
     * For use in interactive testing
     */
    forceMode?: ComponentState["mode"];
    rows?: number;
}

type Action =
    | { type: "change"; value: string }
    | { type: "blur"; value: string }
    | { type: "updateUpstream"; value: string };

const invalidAction = (action: Action): never => {
    throw new Error(`unknown action type ${action.type}`);
};
const invalidMode = (mode: ComponentState["mode"]): never => {
    throw new Error(`unknown mode ${mode}`);
};

const reducer: Reducer<ComponentState, Action> = (p, a): ComponentState => {
    switch (a.type) {
        case "change":
            return { ...p, mode: "dirty", currentValue: a.value };
        case "blur":
            return {
                ...p,
                mode: p.upstreamValue === a.value ? "pristine" : "pending",
                currentValue: a.value,
            };
        case "updateUpstream":
            switch (p.mode) {
                case "dirty":
                    return { ...p, upstreamValue: a.value };
                case "pending":
                    return {
                        ...p,
                        mode: p.currentValue === a.value ? "pristine" : "pending",
                        upstreamValue: a.value,
                    };
                case "pristine":
                    return { ...p, currentValue: a.value, upstreamValue: a.value };
            }
            return invalidMode(p.mode);
    }
    return invalidAction(a);
};

export const AutosavingInput: React.FC<
    Props & React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>
> = props => {
    const {
        initial,
        onCommit,
        label,
        className,
        multiLine,
        forceMode,
        onEmpty,
        ...rest
    } = props;
    const [state, dispatch] = React.useReducer(reducer, {
        upstreamValue: initial,
        currentValue: initial,
        mode: "pristine",
    });
    const mode = forceMode ?? state.mode;
    const onChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            dispatch({ type: "change", value: e.target.value });
        },
        [dispatch],
    );
    const onBlur = React.useCallback(() => {
        if (mode === "dirty") {
            const commitValue = state.currentValue.trim();
            let blurValue = commitValue;

            if (!commitValue && onEmpty) {
                blurValue = onEmpty();
            }
            dispatch({ type: "blur", value: blurValue });
            onCommit(commitValue);
        }
    }, [dispatch, state.currentValue, mode, onCommit, onEmpty]);
    React.useEffect(() => {
        dispatch({ type: "updateUpstream", value: initial });
    }, [dispatch, initial]);
    if (multiLine) {
        return (
            <div className="relative">
                <TextArea
                    rows={3}
                    value={state.currentValue}
                    onChange={onChange}
                    onBlur={onBlur}
                    autoComplete="off"
                    autoGrow
                    label={label}
                />
                {mode === "pending" ? (
                    <div className="absolute bottom-0 pb-3 right-0 pr-3 flex items-center pointer-events-none">
                        <Spinner />
                    </div>
                ) : null}
            </div>
        );
    } else {
        return (
            <div className={`${className ?? ""} w-full`}>
                <label>
                    {label ? <div className="mb-1">{label}</div> : null}
                    <div className="relative">
                        <span className="box flex-1">
                            <input
                                value={state.currentValue}
                                onChange={onChange}
                                onBlur={onBlur}
                                autoComplete="off"
                                className="form-input  rounded-sm md:rounded w-full w-full disabled:bg-gray-100 border-gray-300 hover:border-brand-600 active:border-brand-400 focus:ring-2 focus:ring-brand-300"
                                {...rest}
                            />
                        </span>
                        {mode === "pending" ? (
                            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                                <Spinner />
                            </div>
                        ) : null}
                    </div>
                </label>
            </div>
        );
    }
};
