import { NoteEntityId } from "@sp-crm/core";
import { ActivityFeed } from "components/activity-feed/activity-feed";
import { ActivityFeedNote } from "components/activity-feed/activity-feed-note";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { GetNotesForEntityQuery, useGetNotesForEntityQuery } from "generated/graphql";
import { produce } from "immer";
import React from "react";
import { useQueryClient } from "react-query";
import { NotesInput } from "./notes-input";

interface ModernNotesProps {
    entityId: NoteEntityId;
}

export const ModernNotes: React.FC<ModernNotesProps> = props => {
    const { entityId } = props;
    const query = useGetNotesForEntityQuery({ entityId });
    const queryClient = useQueryClient();

    const handleNoteChanged = React.useCallback(
        (note: GetNotesForEntityQuery["getNotesForEntity"][0]) => {
            const data = queryClient.getQueryData<GetNotesForEntityQuery>([
                "getNotesForEntity",
                { entityId },
            ]);

            const newData = produce(data, draft => {
                let replaced = false;
                draft.getNotesForEntity = draft.getNotesForEntity.map(n => {
                    if (n._id === note._id) {
                        replaced = true;
                        return note;
                    }
                    return n;
                });

                if (!replaced) {
                    draft.getNotesForEntity.unshift(note);
                }
            });

            queryClient.setQueryData<GetNotesForEntityQuery>(
                ["getNotesForEntity", { entityId }],
                newData,
            );
        },
        [queryClient, entityId],
    );

    return (
        <QueryRenderer name="ModernNotes" query={query}>
            {data => (
                <div>
                    <NotesInput
                        refetch={query.refetch}
                        entityId={entityId}
                        initial={data.getNotesForEntity.sort(
                            (a, b) =>
                                new Date(b.dateAdded).getTime() -
                                new Date(a.dateAdded).getTime(),
                        )}
                        onNoteChanged={handleNoteChanged}
                    />
                    <ActivityFeed>
                        {data.getNotesForEntity
                            .filter(n => !n.isDraft)
                            .sort(
                                (a, b) =>
                                    new Date(b.dateAdded).getTime() -
                                    new Date(a.dateAdded).getTime(),
                            )
                            .map(n => (
                                <ActivityFeedNote
                                    entityId={entityId}
                                    refetch={query.refetch}
                                    key={n._id}
                                    note={n}
                                />
                            ))}
                    </ActivityFeed>
                </div>
            )}
        </QueryRenderer>
    );
};
