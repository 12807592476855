import React, { useCallback, useEffect, useState } from "react";
import { DateInputDatePartInput } from "./date-input/date-part-input";
import { DateInputTimePartInput } from "./date-input/time-part-input";
import { DatePart, TimePart } from "./date-input/types";

interface DateInputProps {
    label: string;
    value: Date;
    onChange?: (value: Date) => void;
}

const zeroPad = (input: number, length: 2 | 4): string =>
    input.toString().padStart(length, "0");

const neatDate = (input: Date): [DatePart, TimePart] => {
    return [
        [
            zeroPad(input.getFullYear(), 4),
            zeroPad(input.getMonth() + 1, 2),
            zeroPad(input.getDate(), 2),
        ].join("-") as DatePart,
        [zeroPad(input.getHours(), 2), zeroPad(input.getMinutes(), 2)].join(
            ":",
        ) as TimePart,
    ];
};

export const DateInput: React.FC<DateInputProps> = props => {
    const { value, label, onChange } = props;
    const [currentValue, setCurrentValue] = useState(value);
    useEffect(() => {
        setCurrentValue(value);
    }, [value, setCurrentValue]);
    const [datePart, timePart] = neatDate(currentValue);
    const setDatePart = useCallback(
        (datePartDelta: DatePart) => {
            const [year, month, day] = datePartDelta.split("-");
            setCurrentValue(val => {
                const newValue = new Date(val);
                newValue.setFullYear(parseInt(year, 10));
                newValue.setMonth(parseInt(month, 10) - 1);
                newValue.setDate(parseInt(day, 10));
                if (onChange) {
                    onChange(newValue);
                }
                return newValue;
            });
        },
        [onChange],
    );
    const setTimePart = useCallback(
        (timePartDelta: TimePart) => {
            const [hour, minute] = timePartDelta.split(":");
            setCurrentValue(val => {
                const newValue = new Date(val);
                newValue.setHours(parseInt(hour, 10));
                newValue.setMinutes(parseInt(minute, 10));
                if (onChange) {
                    onChange(newValue);
                }
                return newValue;
            });
        },
        [onChange],
    );
    return (
        <div>
            <div className="w-full">
                <label>
                    {label ? <div className="mb-1">{label}</div> : null}
                    <div className="flex space-x-1 md:space-x-2">
                        <DateInputDatePartInput value={datePart} onChange={setDatePart} />
                        <DateInputTimePartInput value={timePart} onChange={setTimePart} />
                    </div>
                </label>
            </div>
        </div>
    );
};
