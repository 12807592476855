import React from "react";

export const ActivityFeed: React.FC<{ children: React.ReactNode[] }> = props => {
    const elements = React.Children.toArray(props.children);
    return (
        <div className="flow-root">
            <ul role="list" className="-mb-8">
                {React.Children.map(elements, (element, i) => (
                    <li key={(element as React.ReactElement).key}>
                        <div className="relative pb-8">
                            {i !== elements.length - 1 ? (
                                <span
                                    className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                                    aria-hidden="true"
                                />
                            ) : null}
                            {element}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};
