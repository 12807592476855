import { CALENDAR_DATE_NO_YEAR_PLACEHOLDER, CalendarDate } from "@sp-crm/core";
import { produce } from "immer";
import React, { Reducer, useCallback, useEffect } from "react";
import { DeleteButton } from "./action-button";

interface CalendarDateNoYearInputProps {
    initial: CalendarDate | null | undefined;
    onCommit: (value: CalendarDate | null) => void;
    label?: string;
}

interface ComponentState {
    isMonthFocused: boolean;
    isDayFocused: boolean;
    currentMonth: string;
    currentDay: string;
    hasPendingValue: boolean;
}

type Action =
    | { type: "changeDay"; value: string }
    | { type: "changeMonth"; value: string }
    | { type: "upstreamSet"; value: CalendarDate | null }
    | { type: "blurMonth" }
    | { type: "blurDay" }
    | { type: "sendCurrentValue" }
    | { type: "focusMonth" }
    | { type: "focusDay" };

const reducer: Reducer<ComponentState, Action> = (p, a): ComponentState =>
    produce(p, draft => {
        if (a.type === "upstreamSet") {
            draft.currentMonth = a.value
                ? (a.value.toDate().getMonth() + 1).toString()
                : "";
            draft.currentDay = a.value ? a.value.toDate().getDate().toString() : "";
            draft.hasPendingValue = false;
        } else if (a.type === "sendCurrentValue") {
            draft.hasPendingValue = false;
        } else if (a.type === "changeDay") {
            draft.currentDay = a.value;
            draft.hasPendingValue = true;
        } else if (a.type === "changeMonth") {
            draft.currentMonth = a.value;
            draft.hasPendingValue = true;
        } else if (a.type === "blurMonth") {
            draft.isMonthFocused = false;
        } else if (a.type === "blurDay") {
            draft.isDayFocused = false;
        } else if (a.type === "focusMonth") {
            draft.isMonthFocused = true;
        } else if (a.type === "focusDay") {
            draft.isDayFocused = true;
        } else {
            throw new Error(`unknown action type ${a}`);
        }
    });

const initialComponentState: ComponentState = {
    isMonthFocused: false,
    isDayFocused: false,
    currentMonth: "",
    currentDay: "",
    hasPendingValue: false,
};

export const CalendarDateNoYearInput: React.FC<CalendarDateNoYearInputProps> = props => {
    const { initial, onCommit, label } = props;
    const [state, dispatch] = React.useReducer(reducer, initialComponentState);
    useEffect(() => {
        dispatch({ type: "upstreamSet", value: initial });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(() => {
        if (!state.hasPendingValue) {
            return;
        }
        if (state.isDayFocused) {
            return;
        }
        if (state.currentMonth === "" && state.currentDay === "") {
            onCommit(null);
        }
        if (state.currentMonth === "" || state.currentDay === "") {
            return;
        }
        const month = parseInt(state.currentMonth, 10);
        const day = parseInt(state.currentDay, 10);
        if (isNaN(month) || isNaN(day)) {
            return;
        }
        dispatch({ type: "sendCurrentValue" });
        onCommit(new CalendarDate(CALENDAR_DATE_NO_YEAR_PLACEHOLDER, month, day));
    }, [state]); // eslint-disable-line react-hooks/exhaustive-deps
    const onChangeMonth = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
        dispatch({ type: "changeMonth", value: e.target.value });
    }, []);
    const onChangeDay = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({ type: "changeDay", value: e.target.value });
    }, []);
    const onBlurMonth = useCallback(() => {
        dispatch({ type: "blurMonth" });
    }, []);
    const onBlurDay = useCallback(() => {
        dispatch({ type: "blurDay" });
    }, []);
    const onFocusMonth = useCallback(() => {
        dispatch({ type: "focusMonth" });
    }, []);
    const onFocusDay = useCallback(() => {
        dispatch({ type: "focusDay" });
    }, []);
    const clearValue = useCallback(() => {
        dispatch({ type: "upstreamSet", value: null });
        onCommit(null);
    }, [onCommit]);

    const errorDay =
        state.currentMonth !== "" && state.currentDay === "" && !state.isDayFocused;
    const errorMonth =
        state.currentDay !== "" && state.currentMonth === "" && !state.isMonthFocused;

    return (
        <label>
            {label ? <div>{label}</div> : null}
            <div className="flex space-x-1 items-center">
                <select
                    onFocus={onFocusMonth}
                    onBlur={onBlurMonth}
                    value={state.currentMonth}
                    onChange={onChangeMonth}
                    className={`form-select rounded-sm md:rounded disabled:bg-gray-100 border-gray-300 hover:border-brand-600 active:border-brand-400 focus:ring-2 focus:ring-brand-300 ${
                        errorMonth ? "bg-red-300" : ""
                    }`}>
                    <option value="">Month</option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                </select>
                <input
                    onFocus={onFocusDay}
                    onBlur={onBlurDay}
                    onChange={onChangeDay}
                    className={`form-input w-24 rounded-sm md:rounded disabled:bg-gray-100 border-gray-300 hover:border-brand-600 active:border-brand-400 focus:ring-2 focus:ring-brand-300 ${
                        errorDay ? "bg-red-300" : ""
                    }`}
                    placeholder="Day"
                    maxLength={2}
                    width={2}
                    value={state.currentDay}
                    type="number"
                />
                <DeleteButton onClick={clearValue} backgroundColor="bg-white" />
            </div>
        </label>
    );
};
