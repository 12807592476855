import * as React from "react";
interface IconProps {
    className?: string;
}

export const BoldIcon: React.FC<IconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-bold"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}>
        <path d="M0 0h24v24H0z" stroke="none" />
        <path d="M7 5h6a3.5 3.5 0 0 1 0 7H7zM13 12h1a3.5 3.5 0 0 1 0 7H7v-7" />
    </svg>
);

export const AlignCenterIcon: React.FC<IconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-align-center"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}>
        <path d="M0 0h24v24H0z" stroke="none" />
        <path d="M4 6h16M8 12h8M6 18h12" />
    </svg>
);

export const AlignLeftIcon: React.FC<IconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-align-left"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}>
        <path d="M0 0h24v24H0z" stroke="none" />
        <path d="M4 6h16M4 12h10M4 18h14" />
    </svg>
);

export const AlignRightIcon: React.FC<IconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-align-right"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}>
        <path d="M0 0h24v24H0z" stroke="none" />
        <path d="M4 6h16M10 12h10M6 18h14" />
    </svg>
);

export const IndentIncreaseIcon: React.FC<IconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-indent-increase"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}>
        <path d="M0 0h24v24H0z" stroke="none" />
        <path d="M20 6H9M20 12h-7M20 18H9M4 8l4 4-4 4" />
    </svg>
);

export const IndentDecreaseIcon: React.FC<IconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-indent-decrease"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}>
        <path d="M0 0h24v24H0z" stroke="none" />
        <path d="M20 6h-7M20 12h-9M20 18h-7M8 8l-4 4 4 4" />
    </svg>
);

export const ItalicIcon: React.FC<IconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-italic"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}>
        <path d="M0 0h24v24H0z" stroke="none" />
        <path d="M11 5h6M7 19h6M14 5l-4 14" />
    </svg>
);

export const ListNumbersIcon: React.FC<IconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-list-numbers"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        viewBox="0 0 24 24"
        {...props}>
        <path d="M0 0h24v24H0z" stroke="none" />
        <path d="M11 6h9M11 12h9M12 18h8M4 16a2 2 0 1 1 4 0c0 .591-.5 1-1 1.5L4 20h4M6 10V4L4 6" />
    </svg>
);

export const ListIcon: React.FC<IconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-list"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}>
        <path d="M0 0h24v24H0z" stroke="none" />
        <path d="M9 6h11M9 12h11M9 18h11M5 6v.01M5 12v.01M5 18v.01" />
    </svg>
);

export const UnderlineIcon: React.FC<IconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-underline"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}>
        <path d="M0 0h24v24H0z" stroke="none" />
        <path d="M7 5v5a5 5 0 0 0 10 0V5M5 19h14" />
    </svg>
);

export const TextSizeIcon: React.FC<IconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-text-size"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}>
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M3 7v-2h13v2"></path>
        <path d="M10 5v14"></path>
        <path d="M12 19h-4"></path>
        <path d="M15 13v-1h6v1"></path>
        <path d="M18 12v7"></path>
        <path d="M17 19h2"></path>
    </svg>
);

export const TextColorIcon: React.FC<IconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-text-color"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}>
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M9 15v-7a3 3 0 0 1 6 0v7"></path>
        <path d="M9 11h6"></path>
        <path d="M5 19h14"></path>
    </svg>
);

export const TriangleDownIcon: React.FC<IconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-triangle-inverted-filled"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}>
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path
            d="M20.118 3h-16.225a2.914 2.914 0 0 0 -2.503 4.371l8.116 13.549a2.917 2.917 0 0 0 4.987 .005l8.11 -13.539a2.914 2.914 0 0 0 -2.486 -4.386z"
            strokeWidth="0"
            fill="currentColor"></path>
    </svg>
);

export const HighlightIcon: React.FC<IconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-highlight"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}>
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M3 19h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4"></path>
        <path d="M12.5 5.5l4 4"></path>
        <path d="M4.5 13.5l4 4"></path>
        <path d="M21 15v4h-8l4 -4z"></path>
    </svg>
);

export const ClearFormattingIcon: React.FC<IconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-clear-formatting"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}>
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M17 15l4 4m0 -4l-4 4"></path>
        <path d="M7 6v-1h11v1"></path>
        <path d="M7 19l4 0"></path>
        <path d="M13 5l-4 14"></path>
    </svg>
);

export const InsertTemplateIcon: React.FC<IconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-mail-plus"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}>
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M12 19h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v5.5"></path>
        <path d="M16 19h6"></path>
        <path d="M19 16v6"></path>
        <path d="M3 7l9 6l9 -6"></path>
    </svg>
);

export const SignatureIcon: React.FC<IconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-signature"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}>
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M3 17c3.333 -3.333 5 -6 5 -8c0 -3 -1 -3 -2 -3s-2.032 1.085 -2 3c.034 2.048 1.658 4.877 2.5 6c1.5 2 2.5 2.5 3.5 1l2 -3c.333 2.667 1.333 4 3 4c.53 0 2.639 -2 3 -2c.517 0 1.517 .667 3 2"></path>
    </svg>
);

export const InsertPlaceholderIcon: React.FC<IconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon icon-tabler icon-tabler-braces"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}>
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M7 4a2 2 0 0 0 -2 2v3a2 3 0 0 1 -2 3a2 3 0 0 1 2 3v3a2 2 0 0 0 2 2"></path>
        <path d="M17 4a2 2 0 0 1 2 2v3a2 3 0 0 0 2 3a2 3 0 0 0 -2 3v3a2 2 0 0 1 -2 2"></path>
    </svg>
);
