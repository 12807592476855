import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import {
    CheckIcon,
    ChevronDownIcon,
    DocumentIcon,
    DocumentTextIcon,
    ExclamationTriangleIcon,
    PaperClipIcon,
    PhotoIcon,
    TableCellsIcon,
} from "@heroicons/react/24/outline";
import { FileId, formatFileSize } from "@sp-crm/core";
import { DeleteButton } from "components/ui/action-button";
import { SearchInput } from "components/ui/search-input";
import React, { Fragment, useMemo } from "react";
import { ComposeMessageQuery } from "../../generated/graphql";

const byteLengthLimit = 1024 * 1024 * 20; // 20MB

interface Props {
    scopes: ComposeMessageQuery["composeMessage"]["files"];
    selectedFileIds: FileId[];
    onSelectFile: (fileId: FileId) => void;
}
const GetFileIcon = (
    mimeType: string | undefined | null,
): React.FC<React.ComponentProps<"svg">> => {
    if (!mimeType) {
        return DocumentTextIcon;
    }
    const match = mimeType.toLocaleLowerCase();

    if (match.indexOf("pdf") >= 0) {
        return DocumentIcon;
    }
    if (match.indexOf("sheet") >= 0) {
        return TableCellsIcon;
    }
    if (match.indexOf("image") >= 0) {
        return PhotoIcon;
    }
    return DocumentTextIcon;
};

const buttonClasses =
    "twoverride inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm rounded-sm sm:rounded text-gray-900 bg-gray-100 " +
    "hover:bg-gray-200 " +
    "space-x-1 items-center justify-around" +
    "focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-200";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
}

export const Attachments: React.FC<Props> = props => {
    const { scopes, selectedFileIds, onSelectFile } = props;

    const [searchInput, setSearchInput] = React.useState("");

    const humanReadableSelectedFiles: ComposeMessageQuery["composeMessage"]["files"][0]["files"] =
        [];

    scopes.forEach(s => {
        const files = s.files
            .slice()
            .sort((a, b) =>
                (a.originalFilename ?? "")
                    .toLocaleLowerCase()
                    .localeCompare(b.originalFilename ?? ""),
            );
        files.forEach(f => {
            if (selectedFileIds.includes(f.id)) {
                humanReadableSelectedFiles.push(f);
            }
        });
    });

    const handleSelect = React.useCallback(
        (file: ComposeMessageQuery["composeMessage"]["files"][0]["files"][0]) => () => {
            onSelectFile(file.id);
            setSearchInput("");
        },
        [onSelectFile, setSearchInput],
    );

    const filteredScopes = useMemo(() => {
        const result = scopes
            .filter(s => s.files.length > 0)
            .map(s => {
                return {
                    ...s,
                    files: s.files.sort((a, b) => {
                        return (a.originalFilename ?? "")
                            .toLocaleLowerCase()
                            .localeCompare(b.originalFilename ?? "");
                    }),
                };
            });

        if (!searchInput?.trim()) {
            return result;
        }

        return result.map(s => {
            return {
                ...s,
                files: s.files.filter(f =>
                    (f.originalFilename ?? "")
                        .toLocaleLowerCase()
                        .includes(searchInput.trim().toLocaleLowerCase()),
                ),
            };
        });
    }, [scopes, searchInput]);

    if (scopes.length === 0) {
        return null;
    }

    return (
        <div className="space-y-2">
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <MenuButton className={buttonClasses}>
                        <div>
                            <PaperClipIcon
                                className="text-gray-500 h-4 w-4"
                                aria-hidden="true"
                            />
                        </div>
                        <div>Attach a file</div>
                        <div>
                            <ChevronDownIcon className="h-4 w-4" aria-hidden="true" />
                        </div>
                    </MenuButton>
                </div>
                <MenuItems className="z-1400 origin-top-left absolute left-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none">
                    {scopes.every(s => s.files.length <= 0) ? (
                        <p className="p-6 text-gray-700">
                            Upload files in the Files tab to attach files to this message.
                        </p>
                    ) : (
                        <div>
                            <SearchInput
                                autoFocus={true}
                                value={searchInput}
                                onChange={e => {
                                    setSearchInput(e.target.value);
                                }}
                            />
                            {filteredScopes.map(scope => (
                                <Fragment key={scope.entityName}>
                                    <div className="px-4 py-2 text-brand-600 text-sm font-bold">
                                        {scope.entityName}
                                    </div>
                                    {scope.files.map(file => {
                                        const FileIcon = GetFileIcon(file.mimeType);
                                        return (
                                            <MenuItem
                                                key={file.id}
                                                disabled={
                                                    file.byteLength > byteLengthLimit
                                                }>
                                                {({ focus, disabled }) => (
                                                    <a
                                                        onClick={handleSelect(file)}
                                                        className={classNames(
                                                            focus
                                                                ? "bg-brand-100 text-brand-900"
                                                                : disabled
                                                                ? "text-gray-400"
                                                                : "text-gray-700",
                                                            "twoverride group px-4 py-2 text-sm flex items-center",
                                                        )}>
                                                        <div>
                                                            {selectedFileIds.includes(
                                                                file.id,
                                                            ) ? (
                                                                <CheckIcon
                                                                    aria-hidden="true"
                                                                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-brand-600"
                                                                />
                                                            ) : (
                                                                <div className="mr-3 h-5 w-5" />
                                                            )}
                                                        </div>
                                                        <div>
                                                            <FileIcon
                                                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-brand-600"
                                                                aria-hidden="true"
                                                            />
                                                        </div>
                                                        <div className="flex-1 space-y-2">
                                                            <div className="flex space-x-4 items-center">
                                                                <p className="flex-1">
                                                                    {file.originalFilename ??
                                                                        "(unknown)"}
                                                                </p>
                                                                <p
                                                                    className={`text-gray-${
                                                                        disabled
                                                                            ? "400"
                                                                            : "700"
                                                                    } text-sm`}>
                                                                    {formatFileSize(
                                                                        file.byteLength,
                                                                    ) || " "}
                                                                </p>
                                                            </div>
                                                            {disabled ? (
                                                                <p className="text-xs text-yellow-600 flex items-center space-x-1">
                                                                    <ExclamationTriangleIcon className="w-4 h-4" />
                                                                    <span>
                                                                        Files over{" "}
                                                                        {formatFileSize(
                                                                            byteLengthLimit,
                                                                        )}{" "}
                                                                        cannot be attached
                                                                    </span>
                                                                </p>
                                                            ) : null}
                                                        </div>
                                                    </a>
                                                )}
                                            </MenuItem>
                                        );
                                    })}
                                </Fragment>
                            ))}
                        </div>
                    )}
                </MenuItems>
            </Menu>
            {humanReadableSelectedFiles.length > 0 ? (
                <ul className="list-disc list-inside">
                    {humanReadableSelectedFiles.map(f => {
                        const FileIcon = GetFileIcon(f.mimeType);
                        return (
                            <li key={f.id}>
                                <div className="inline-flex items-center space-x-2">
                                    <FileIcon className="w-4 h-4" />
                                    <p>{f.originalFilename ?? "Untitled"}</p>
                                    <p className="text-sm pl-4 text-gray-700">
                                        {formatFileSize(f.byteLength) || " "}
                                    </p>
                                    <DeleteButton
                                        backgroundColor="bg-white"
                                        size="sm"
                                        onClick={handleSelect(f)}
                                    />
                                </div>
                            </li>
                        );
                    })}
                </ul>
            ) : null}
        </div>
    );
};
