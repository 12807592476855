import { CommunityId, FileEntityId, PhotoId } from "@sp-crm/core";
import {
    MultiplePhotoUpload,
    UploadedPhoto,
} from "components/shared/multiple-photo-upload";
import {
    useDeletePhotoMutation,
    useGetPhotosForEntityQuery,
    usePatchPhotoMutation,
    usePatchPhotosMutation,
} from "generated/graphql";
import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { addCommunityPhotos } from "store/actions";

interface EntityPhotosProps {
    entityId: FileEntityId;
}

export const EntityPhotos: React.FC<EntityPhotosProps> = props => {
    const { entityId } = props;
    const dispatch = useDispatch();

    const getPhotos = useGetPhotosForEntityQuery({
        entityId,
    });

    const deletePhoto = useDeletePhotoMutation();
    const patchPhotos = usePatchPhotosMutation();
    const patchPhoto = usePatchPhotoMutation();

    const handleDeletePhoto = useCallback(
        async (photoId: PhotoId) => {
            await deletePhoto.mutateAsync({
                params: {
                    id: photoId,
                    entityId,
                },
            });
            getPhotos.refetch();
        },
        [deletePhoto, getPhotos, entityId],
    );

    const handleUpdateOrders = useCallback(
        async (photos: UploadedPhoto[]) => {
            const photoOrder = photos.map(photo => ({
                id: photo.id,
                entityId,
                order: photo.order,
            }));
            await patchPhotos.mutateAsync({
                params: photoOrder,
            });
            getPhotos.refetch();
        },
        [patchPhotos, getPhotos, entityId],
    );

    const handleAddNewPhotos = useCallback(
        async (photos: File[]) => {
            await addCommunityPhotos(entityId as CommunityId, photos, dispatch);
            getPhotos.refetch();
        },
        [dispatch, entityId, getPhotos],
    );

    const handleUpdatePhotoDescription = useCallback(
        async (photoId: PhotoId, description: string) => {
            await patchPhoto.mutateAsync({
                params: {
                    id: photoId,
                    entityId,
                    originalFile: {
                        description,
                    },
                },
            });
            getPhotos.refetch();
        },
        [getPhotos, patchPhoto, entityId],
    );

    if (!getPhotos.data) {
        return null;
    }

    return (
        <MultiplePhotoUpload
            photos={getPhotos.data.getPhotosForEntity}
            onAddNewPhotos={handleAddNewPhotos}
            onDeletePhoto={handleDeletePhoto}
            onUpdatePhotoOrders={handleUpdateOrders}
            onUpdatePhotoDescription={handleUpdatePhotoDescription}
            title={"Photos"}
        />
    );
};
