import { EmailTemplateId, EmailTemplateType, FileId } from "@sp-crm/core";

export const signatureClass = "crm-signature";
export const composeInitialContent = `<div></div><br /><div class="${signatureClass}"></div>`;
export type UnmigratedTemplate = {
    description: string;
    isDefaultSignature: boolean;
    body: string;
    subject: string | null;
    id: EmailTemplateId;
    migrationVersion: number;
    type: EmailTemplateType | null;
    attachments: FileId[] | null;
};
export type Template = UnmigratedTemplate & { __type: "MigratedTemplate" };
export const buildInitialContentForSignature = (signature: Template): string => {
    return `<div></div><br /><div class="${signatureClass}">${signature.body}</div>`;
};

export interface HtmlToken {
    label: string;
    value: string;
}

export const buildInitialEmailContent = (
    template: Template | null,
    signature: Template | null,
) => {
    return `<div>${template ? template.body : ""}</div><br />${
        signature ? `<div class="${signatureClass}">${signature.body}</div>` : ""
    }`;
};
