import { StarIcon as StarIconSolid } from "@heroicons/react/20/solid";
import { StarIcon as StarIconOutline, UserIcon } from "@heroicons/react/24/outline";
import { CommunityId, Contact, ContactId } from "@sp-crm/core";
import { QueryRenderer } from "components/clients/show-client/community-comparison/query-renderer";
import { ContactCard } from "components/contacts/card";
import { ContactEditor } from "components/contacts/contact-editor";
import { Icon } from "components/icon";
import { DeleteButton, EditButton } from "components/ui/action-button";
import { Panel } from "components/ui/panel/panel";
import { PanelType } from "components/ui/panel/panel-type";
import { SecondaryButton } from "components/ui/secondary-button";
import {
    AdvancedSearchEntityType,
    ContactWithLinksFragment,
    GetCommunityContactsQuery,
    useDeleteCommunityContactMutation,
    useGetCommunityContactsQuery,
} from "generated/graphql";
import { produce } from "immer";
import React, { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { Link } from "react-router-dom";
import { useRegionId } from "store/selectors/hooks";
import { CommunityContactResult } from "types/contacts";

interface CommunityContactItemProps {
    communityContact: CommunityContactResult;
    onEdit: (communityContact: CommunityContactResult) => void;
    onDelete: (communityContact: CommunityContactResult) => void;
}

const CommunityContactItem: React.FC<CommunityContactItemProps> = props => {
    const { communityContact, onEdit, onDelete } = props;

    const handleEdit = useCallback(() => {
        onEdit(communityContact);
    }, [communityContact, onEdit]);

    const handleDelete = useCallback(() => {
        onDelete(communityContact);
    }, [communityContact, onDelete]);

    const contactNotes = useMemo(() => {
        if (communityContact.referenceContact) {
            return communityContact.referenceContact.summary;
        }
        return communityContact.contact.contactNotes;
    }, [communityContact]);

    return (
        <li
            key={communityContact.contactId}
            className="border border-gray-200 rounded-lg p-4">
            <div className="flex items-start">
                <div className="flex-1">
                    <ContactCard
                        parentEntityType={AdvancedSearchEntityType.Community}
                        parentId={communityContact.communityId}
                        contact={Contact.load({
                            ...communityContact.contact,
                            contactNotes,
                        })}
                        icon={
                            communityContact.primary ? (
                                <StarIconSolid className="w-4 h-4 text-yellow-400" />
                            ) : (
                                <StarIconOutline className="w-4 h-4 text-gray-400" />
                            )
                        }
                    />
                </div>
                <div className="flex items-center">
                    {communityContact.referenceContact ? (
                        <Link
                            to={`/references/contacts/show/${communityContact.referenceContact.id}`}>
                            <UserIcon className="w-7 h-7 p-1 text-gray-400 hover:text-gray-500 hover:bg-gray-100 rounded cursor-pointer" />
                        </Link>
                    ) : null}
                    <EditButton backgroundColor="bg-white" onClick={handleEdit} />
                    <DeleteButton
                        backgroundColor="bg-white"
                        onClick={handleDelete}
                        confirm={{
                            title: `Deleting ${
                                communityContact.contact.name || "Contact"
                            }`,
                            message: `Really delete the contact info for "${
                                communityContact.contact.name || "(No Name)"
                            }"?`,
                        }}
                    />
                </div>
            </div>
        </li>
    );
};

interface CommunityContactsProps {
    communityId: CommunityId;
}

export const CommunityContacts: React.FC<CommunityContactsProps> = props => {
    const { communityId } = props;

    const [editingContactId, setEditingContactId] = useState<ContactId | null | "_new_">(
        null,
    );

    const regionId = useRegionId();
    const communityContactsQuery = useGetCommunityContactsQuery({
        communityId,
        regionId,
    });
    const queryClient = useQueryClient();
    const deleteMutation = useDeleteCommunityContactMutation();

    const handleAddContact = useCallback(
        async (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            e.preventDefault();

            setEditingContactId("_new_");
        },
        [setEditingContactId],
    );

    const handleEdit = useCallback(
        (communityContact: CommunityContactResult) => {
            setEditingContactId(communityContact.contactId);
        },
        [setEditingContactId],
    );

    const handleDelete = useCallback(
        async (communityContact: CommunityContactResult) => {
            await deleteMutation.mutateAsync({
                communityId: communityContact.communityId,
                contactId: communityContact.contactId,
            });

            const data = queryClient.getQueryData<GetCommunityContactsQuery>([
                "getCommunityContacts",
                { communityId: communityContact.communityId, regionId },
            ]);

            const newData = produce(data, draft => {
                if (draft) {
                    draft.getCommunityContacts = draft.getCommunityContacts.filter(
                        cc =>
                            cc.communityId !== communityContact.communityId ||
                            cc.contactId !== communityContact.contactId,
                    );
                }
            });

            queryClient.setQueryData<GetCommunityContactsQuery>(
                [
                    "getCommunityContacts",
                    { communityId: communityContact.communityId, regionId },
                ],
                newData,
            );
        },
        [deleteMutation, queryClient, regionId],
    );

    const handleDismiss = useCallback(() => {
        setEditingContactId(null);
    }, [setEditingContactId]);

    const handleSave = useCallback(
        (contact: ContactWithLinksFragment) => {
            const data = queryClient.getQueryData<GetCommunityContactsQuery>([
                "getCommunityContacts",
                { communityId, regionId },
            ]);

            const newData = produce(data, draft => {
                if (draft) {
                    const matchingIndex = draft.getCommunityContacts.findIndex(
                        cc =>
                            cc.communityId === communityId && cc.contactId === contact.id,
                    );

                    if (matchingIndex >= 0) {
                        draft.getCommunityContacts[matchingIndex].contact = contact;
                        draft.getCommunityContacts[matchingIndex].primary =
                            contact.communityContacts.some(
                                cc => cc.communityId === communityId && cc.primary,
                            );
                        draft.getCommunityContacts[matchingIndex].referenceContact =
                            contact.referenceContacts.find(
                                r => r.regionId === regionId,
                            ) || null;
                    } else {
                        draft.getCommunityContacts.push({
                            communityId,
                            contactId: contact.id,
                            primary: contact.communityContacts.some(
                                cc => cc.communityId === communityId && cc.primary,
                            ),
                            contact,
                            referenceContact:
                                contact.referenceContacts.find(
                                    r => r.regionId === regionId,
                                ) || null,
                        });
                    }
                }
            });

            queryClient.setQueryData<GetCommunityContactsQuery>(
                ["getCommunityContacts", { communityId, regionId }],
                newData,
            );
        },
        [queryClient, regionId, communityId],
    );

    return (
        <div className="input-form-block-no-bottom-margin">
            <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2">
                    <div className="w-7">
                        <Icon name="26_CommunityContacts" />
                    </div>
                    <span className="text-lg text-gray-700">Community contacts</span>
                </div>
                <SecondaryButton onClick={handleAddContact}>Add contact</SecondaryButton>
            </div>
            <QueryRenderer
                query={communityContactsQuery}
                name="CommunityContacts.getCommunityContacts">
                {data => (
                    <>
                        <ul className="2xl:grid 2xl:grid-cols-2 2xl:gap-4 mt-4 space-y-4 2xl:space-y-0">
                            {data.getCommunityContacts.map(communityContact => (
                                <CommunityContactItem
                                    key={communityContact.contactId}
                                    communityContact={communityContact}
                                    onEdit={handleEdit}
                                    onDelete={handleDelete}
                                />
                            ))}
                        </ul>
                    </>
                )}
            </QueryRenderer>
            <Panel
                isOpen={!!editingContactId}
                headerText={editingContactId === "_new_" ? "Add Contact" : "Edit Contact"}
                hasCloseButton={false}
                type={PanelType.large}>
                {editingContactId ? (
                    <ContactEditor
                        initialContactId={
                            editingContactId === "_new_" ? null : editingContactId
                        }
                        parentEntityType={AdvancedSearchEntityType.Community}
                        parentEntityId={communityId}
                        onSaved={handleSave}
                        onClose={handleDismiss}
                    />
                ) : null}
            </Panel>
        </div>
    );
};
