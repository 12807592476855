import { emailTokens } from "@sp-crm/core";
import React from "react";

interface MandatoryEmailTokenProps {
    use: "community-comparison" | "disclosure" | "hosted-intake";
}

export const MandatoryEmailToken: React.FC<MandatoryEmailTokenProps> = props => {
    const { use } = props;

    if (use === "community-comparison") {
        return (
            <em>
                <span className="block">
                    You&apos;ll need to add &quot;
                    <code>{emailTokens.intakeLiveLink}</code>&quot; to the message body so
                    we know where to insert the community comparison link.
                </span>
            </em>
        );
    }

    if (use === "disclosure") {
        return (
            <em>
                <span className="block">
                    You&apos;ll need to add &quot;
                    <code>{emailTokens.intakeLiveLink}</code>
                    &quot; to the message body so we know where to insert the disclosure
                    link.
                </span>
            </em>
        );
    }

    if (use === "hosted-intake") {
        return (
            <em>
                <span className="block">
                    You&apos;ll need to add &quot;
                    <code>{emailTokens.intakeLiveLink}</code>
                    &quot; to the message body so we know where to insert the link to the
                    form.
                </span>
            </em>
        );
    }

    const exhaustiveCheck: never = use;
    throw new Error(`Unhandled use case: ${exhaustiveCheck}`);
};
