import * as React from "react";

export interface RadioOption {
    key: string;
    text: string;
    helpText?: string;
}

interface Props {
    onChange?: (val: string) => void;
    options: RadioOption[];
    value: string;
    label: string;
    className?: string;
    disabled?: boolean;
}

export const Radio: React.FunctionComponent<Props> = props => {
    const { onChange, options, label, value, disabled } = props;
    const handleChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            if (onChange) {
                onChange(e.target.value);
            }
        },
        [onChange],
    );
    return (
        <div className="space-y-1">
            {label ? <div>{label}</div> : null}
            {options.map(o => (
                <div className="space-y-0.5" key={o.key}>
                    <label className="flex items-center">
                        <input
                            onChange={handleChange}
                            checked={value === o.key}
                            value={o.key}
                            type="radio"
                            className="form-radio  disabled:bg-gray-100 border-gray-400 hover:border-brand-600 text-brand-600 active:border-brand-400 focus:ring-2 focus:ring-brand-300"
                            disabled={disabled}
                        />
                        <div className="ml-2">{o.text}</div>
                    </label>
                    {o.helpText ? (
                        <div className="ml-6 text-sm text-gray-500">{o.helpText}</div>
                    ) : null}
                </div>
            ))}
        </div>
    );
};
